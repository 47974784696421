import React, { useEffect, useRef } from "react";
import { Form, Input, Button, Typography } from "antd";
import { formFocusHelper } from "../helpers/formFocusHelper";

const { Title } = Typography;

const ClassroomEditForm = ({
  classroom = {},
  onFinish,
  refreshClassrooms,
  title,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    return () => formFocusHelper(ref); 
  }, []);

  const handleFinish = (classroomData) => {
    onFinish(classroomData)
      .then(() => {
        refreshClassrooms();
      })
      .catch((error) => {
        console.error("Error handling classroom:", error);
      });
  };

  return (
    <>
      <Title level={2}>{title}</Title>
      <Form
        initialValues={classroom}
        onFinish={handleFinish}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please input the name." }]}
        >
          <Input ref={ref}/>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {classroom.id ? "Update Classroom" : "Create Classroom"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ClassroomEditForm;
