import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import moment from "moment";
import { Button, Row, Col, List } from "antd";
import { useAuth } from "../contexts/AuthContext";
import ClassroomSelector from "../components/ClassroomSelector";
import LoadingSpinner from "../components/LoadingSpinner";
import IncompleteSetupMessage from "../components/IncompleteSetupMessage";
import { getFullName } from "../helpers/childHelper";
import {
  recordAttendance,
  getAttendanceLogsForClassroomOnDate,
} from "../api/AttendanceAPI";
import { getChildrenInClassroom } from "../api/SchoolAPI";
import { shouldShowClassroomSelector } from "../helpers/teacherHelper";
import { getIncompleteSetupMessage } from "../helpers/setupHelper";
import { trackEvent } from "../utils/mixpanel";
import requestHandler from "../utils/requestHandler";
import "../css/AttendancePage.css";
import "../css/ChildAttendanceList.css";

const AttendancePage = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");
  const [selectedClassroom, setSelectedClassroom] = useState(null);
  const [attendance, setAttendance] = useState({});
  const [children, setChildren] = useState([]);
  const [attendanceRecorded, setAttendanceRecorded] = useState(false);
  const [recordedBy, setRecordedBy] = useState(null);

  useEffect(() => {
    setDate(moment().format("YYYY-MM-DD"));
  }, []);

  useEffect(() => {
    if (currentUser.classrooms && currentUser.classrooms.length > 0) {
      setSelectedClassroom(currentUser.classrooms[0].id);
    }
  }, [currentUser.classrooms]);

  useEffect(() => {
    if (selectedClassroom && date) {
      setLoading(true);
      const fetchData = async () => {
        try {
          const attendanceData = await requestHandler.execute(
            getAttendanceLogsForClassroomOnDate(selectedClassroom, date),
            null, // No success message needed for fetching
            "Error fetching attendance data"
          );

          if (
            attendanceData &&
            attendanceData.logs &&
            attendanceData.logs.length > 0
          ) {
            const initialAttendance = attendanceData.logs.reduce((acc, log) => {
              acc[log.child_id] = log.present;
              return acc;
            }, {});

            setAttendance(initialAttendance);
            setAttendanceRecorded(true);
            setRecordedBy(attendanceData.recorded_by);

            setChildren(
              attendanceData.logs.map((log) => ({
                child_id: log.child_id,
                first_name: log.first_name,
                last_name: log.last_name,
              }))
            );
          } else {
            const childrenData = await requestHandler.execute(
              getChildrenInClassroom(selectedClassroom),
              null, // No success message needed for fetching
              "Error fetching children data"
            );
            setChildren(childrenData);
            const initialAttendance = childrenData.reduce((acc, child) => {
              acc[child.child_id] = true; // Default to present
              return acc;
            }, {});
            setAttendance(initialAttendance);
            setAttendanceRecorded(false); // No attendance recorded initially
            setRecordedBy(currentUser);
          }
          trackEvent("AttendancePage Loaded", {}, currentUser);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [selectedClassroom, date, currentUser]);

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setDate(newDate);
  };

  const handleRecordAttendance = async () => {
    setLoading(true);
    const attendanceLogs = Object.entries(attendance).map(
      ([childId, isPresent]) => ({
        date: date,
        classroom_id: selectedClassroom,
        child_id: childId,
        present: isPresent,
        note: "",
      })
    );

    try {
      await requestHandler.execute(
        recordAttendance(attendanceLogs),
        "Attendance recorded successfully.",
        "Failed to record attendance"
      );

      trackEvent(
        "Children Attendance Recorded",
        {
          selectedClassroom,
          date: date,
          attendanceLogs,
        },
        currentUser
      );
      setAttendanceRecorded(true);
      window.scrollTo(0, 0); // Scroll to top of the page
    } finally {
      setLoading(false);
    }
  };

  const toggleAttendance = (childId) => {
    setAttendance((prevAttendance) => {
      const newAttendance = {
        ...prevAttendance,
        [childId]: !prevAttendance[childId],
      };

      trackEvent(
        "Child Attendance Marked",
        { childId, isPresent: newAttendance[childId] },
        currentUser
      );

      setAttendanceRecorded(false); // Show "Record Attendance" button if any child state is toggled

      return newAttendance;
    });
  };

  const renderAttendanceSummary = () => {
    const total = children.length;
    const present = Object.values(attendance).filter((status) => status).length;
    const absent = total - present;
    const presentPercentage = ((present / total) * 100).toFixed(1);
    const absentPercentage = ((absent / total) * 100).toFixed(1);

    return (
      <div className="attendance-summary-container">
        <table className="attendance-summary-table">
          <tbody>
            <tr>
              <td>Total:</td>
              <td>{total}</td>
            </tr>
            <tr>
              <td>Present:</td>
              <td className="attendance-summary-present">
                {present} ({presentPercentage}%)
              </td>
            </tr>
            <tr>
              <td>Absent:</td>
              <td className="attendance-summary-absent">
                {absent} ({absentPercentage}%)
              </td>
            </tr>
            {recordedBy && (
              <tr>
                <td>Recorded By:</td>
                <td>{getFullName(recordedBy)}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const incompleteMessage = getIncompleteSetupMessage(currentUser);

  return (
    <div>
      <Helmet>
        <title>Record Attendance | Maidan</title>
      </Helmet>
      {loading ? (
        <LoadingSpinner />
      ) : incompleteMessage ? (
        <IncompleteSetupMessage
          title="Development Record"
          message={incompleteMessage}
        />
      ) : (
        <>
          <Row
            align="middle"
            justify="space-between"
            gutter={8}
            style={{ marginBottom: 20 }}
          >
            <Col>
              <h1 className="page-heading-without-bottom-margin">
                Record Attendance
              </h1>
            </Col>
            <Col>
              <input
                type="date"
                value={date}
                onChange={handleDateChange}
                className="custom-date-picker"
                max={moment().format("YYYY-MM-DD")}
              />
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            style={{ marginBottom: 20, alignItems: "center" }}
          >
            {shouldShowClassroomSelector(currentUser) && (
              <Col xs={24} sm={12} md={6}>
                <ClassroomSelector
                  selectedClassroom={selectedClassroom}
                  setSelectedClassroom={setSelectedClassroom}
                />
              </Col>
            )}
          </Row>
          {selectedClassroom && (
            <>
              {attendanceRecorded && renderAttendanceSummary()}
              <List
                className="child-attendance-list"
                dataSource={children}
                renderItem={(child) => (
                  <List.Item
                    className={`attendance-item ${attendance[child.child_id] ? "present" : "absent"}`}
                    onClick={() => toggleAttendance(child.child_id)}
                  >
                    <span className="child-name">{getFullName(child)}</span>
                  </List.Item>
                )}
              />
              {!attendanceRecorded && (
                <Button
                  type="primary"
                  className="record-attendance-button"
                  onClick={handleRecordAttendance}
                >
                  Record Attendance
                </Button>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AttendancePage;
