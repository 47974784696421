import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Button, Typography, Upload, Image, message } from "antd";
import LanguageDropdown from "./LanguageDropdown";
import PublisherDropdown from "./PublisherDropdown";
import CategoriesDropdown from "./CategoriesDropdown";
import CollectionsDropdown from "./CollectionsDropdown";
import AuthorsDropdown from "./AuthorsDropdown";
import { UploadOutlined } from "@ant-design/icons";
import { validateFile } from "../../helpers/imageHelper";
import { formFocusHelper } from "../../helpers/formFocusHelper";

const { Title } = Typography;

const BookEditForm = ({ book = {}, onFinish, refreshBooks, title }) => {
  const [image, setImage] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    return () => formFocusHelper(ref); 
  }, []);

  const handleImageChange = (file) => {
    setImage(file);
  };

  const handleUpload = async () => {
    if (!image) return null;
    return image;
  };

  const handleFinish = async (bookData) => {
    const s3ImageUrl = await handleUpload();
    if (image) {
      bookData.cover_image_url = s3ImageUrl;
    }
    onFinish(bookData)
      .then(() => {
        refreshBooks();
      })
      .catch((error) => {
        console.error("Error handling book:", error);
      });
  };

  const beforeUpload = (file) => {
    const { isValid, message: errorMessage } = validateFile(file, 2); // Use the helper function
    if (!isValid) {
      message.error(errorMessage);
      return Upload.LIST_IGNORE;
    }

    handleImageChange(file);
    return false; // Prevent default upload behavior
  };

  return (
    <>
      <Title level={2}>{title}</Title>
      <Form
        initialValues={book}
        onFinish={handleFinish}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Please input the book title." }]}
        >
          <Input ref={ref}/>
        </Form.Item>

        <Form.Item name="description" label="Description">
          <Input />
        </Form.Item>

        <Form.Item name="page_count" label="Page Count">
          <Input type="number" />
        </Form.Item>

        <Form.Item
          name="language"
          label="Language"
          rules={[{ required: true, message: "Please select the language." }]}
        >
          <LanguageDropdown />
        </Form.Item>

        <Form.Item name="publisher_id" label="Publisher">
          <PublisherDropdown />
        </Form.Item>

        {/* New Form Item for Authors */}
        <Form.Item name="authors" label="Authors">
          <AuthorsDropdown />
        </Form.Item>

        {/* New Form Item for Categories */}
        <Form.Item name="categories" label="Categories">
          <CategoriesDropdown />
        </Form.Item>

        {/* New Form Item for Collections */}
        <Form.Item name="collections" label="Collections">
          <CollectionsDropdown />
        </Form.Item>

        <Form.Item 
          name="published_year" 
          label="Published Year"
          rules={[
            {
              pattern: /^\d{4}$/,
              message: "Format yyyy",
            },
          ]}>
          <Input type="number" />
        </Form.Item>
        <div style={{ display: "flex", width: "100%" }}>
          <Form.Item
            name="cover_image_url"
            label="cover_image_url"
            style={{ width: "180px" }}
          >
            <Upload
              listType="picture"
              maxCount={1}
              beforeUpload={beforeUpload}
            >
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Form.Item>
          {book.cover_image_url && (
            <Image
              src={`${book.cover_image_url}`}
              style={{ width: "100px", marginTop: "10px", marginLeft: "50px" }}
            />
          )}
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {book.id ? "Update Book" : "Create Book"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default BookEditForm;
