import React, { useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const GradeDistributionHistogram = ({ children }) => {
  const gradeDistribution = useMemo(() => {
    const distribution = {};
    children.forEach((child) => {
      const gradeName = child.grade ? child.grade.name : "Unassigned";
      distribution[gradeName] = (distribution[gradeName] || 0) + 1;
    });

    return Object.entries(distribution)
      .map(([grade, count]) => ({
        grade,
        count,
      }))
      .sort((a, b) => {
        if (a.grade === "Unassigned") return 1;
        if (b.grade === "Unassigned") return -1;
        return a.grade.localeCompare(b.grade);
      });
  }, [children]);

  return (
    <div style={{ width: "100%", height: 300 }}>
      <h3>Grade Distribution</h3>
      <ResponsiveContainer>
        <BarChart
          data={gradeDistribution}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <XAxis dataKey="grade" />
          <YAxis allowDecimals={false} />
          <Tooltip />
          <Bar dataKey="count" fill="#8884d8">
            <LabelList dataKey="count" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GradeDistributionHistogram;
