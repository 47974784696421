import React, { useState, useEffect, useRef } from "react";
import { Menu } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";
import Divider from "@mui/material/Divider";
import { useAbility } from "../hooks/useAbility";
import UserInfo from "./UserInfo";
import { sidebarItems } from "../config/sidebarItems";
import { sidebarStructure } from "../config/sidebarStructure";
import { trackEvent } from "../utils/mixpanel";
import "../css/Sidebar.css";

const Sidebar = ({
  isMobile,
  isVisible,
  onLogout,
  onToggle,
  onMenuItemClick,
  user,
}) => {
  const sidebarRef = useRef(null);
  const ability = useAbility();
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        isVisible &&
        isMobile
      ) {
        onToggle();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, onToggle, isMobile]);

  useEffect(() => {
    const path = location.pathname;
    const splitPath = path.split("/").filter(Boolean);
    const currentKey = splitPath[splitPath.length - 1] || "dashboard";

    setSelectedKeys([currentKey]);

    const role = user?.role;
    const structure = sidebarStructure[role] || [];

    const openSubmenus = structure
      .filter(
        (item) => item.type === "submenu" && item.children.includes(currentKey)
      )
      .map((submenu) => submenu.key);

    setOpenKeys(openSubmenus);
  }, [location.pathname, user]);

  const handleMenuItemClick = (key, path) => {
    trackEvent(
      "Sidebar Item Clicked",
      {
        item: key,
      },
      user
    );

    navigate(path);

    if (isMobile) {
      onMenuItemClick();
    }
  };

  const getMenuItems = () => {
    const role = user?.role;
    const structure = sidebarStructure[role] || [];

    return structure.map((item) => {
      if (item.type === "item") {
        const menuItem = sidebarItems.find((sidebarItem) => sidebarItem.key === item.key);
        return (ability.can("access", menuItem.key) && {
          key: menuItem?.key,
          label: menuItem?.label,
          icon: menuItem?.icon,
          onClick: () => handleMenuItemClick(menuItem?.key, menuItem?.path),
        });
      } else if (item.type === "submenu") {
        return {
          key: item.key,
          label: item.label,
          icon: item.icon,
          children: item.children.map((childKey) => {
            const childItem = sidebarItems.find((i) => i.key === childKey);
            return {
              key: childItem?.key,
              label: childItem?.label,
              icon: childItem?.icon,
              onClick: () => handleMenuItemClick(childItem?.key, childItem?.path),
            };
          }),
        };
      }
      return null;
    });
  };

  return (
    <div
      ref={sidebarRef}
      className={`sidebar ${isVisible ? "visible" : "hidden"}`}
    >
      <div className="sidebar-content">
        {!isMobile && (
          <div className="sidebar-header">
            <Link to="/" className="sidebar-logo">
              maidan
            </Link>
          </div>
        )}
        <Divider />
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          onOpenChange={setOpenKeys}
          items={[
            ...getMenuItems(),
            {
              key: "logout",
              label: "Logout",
              icon: <LogoutOutlined />,
              onClick: () => {
                onLogout();
                trackEvent("Sidebar Item Clicked", { item: "logout" }, user);
              },
            },
          ]}
        />
      </div>
      {process.env.NODE_ENV === "development" && (
        <div className="user-details">
          <UserInfo user={user} />
        </div>
      )}
    </div>
  );
};

export default Sidebar;
