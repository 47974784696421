// client/src/api/AttendanceAPI.js
import axios from "axios";

const baseURL = "/attendance";

const api = axios.create({
  baseURL,
});

export const recordAttendance = async (attendanceLogs) => {
  try {
    const response = await api.post("/", { attendanceRecords: attendanceLogs });
    return response.data;
  } catch (error) {
    console.error("Error recording attendance:", error);
    throw error;
  }
};

export const getAttendanceLogsForClassroom = async (classroomId) => {
  try {
    const response = await api.get(`/logs/classroom/${classroomId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching attendance logs:", error);
    throw error;
  }
};

export const getAttendanceLogsForClassroomOnDate = async (
  classroomId,
  date
) => {
  try {
    const response = await api.get(`/logs`, {
      params: { classroomId, date },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching attendance logs:", error);
    throw error;
  }
};

export const getAttendanceByChild = async (childId) => {
  try {
    const response = await api.get(`/children/${childId}/attendance`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
