import React from "react";
import { Select } from "antd";
import ChildSortOptions from "../ChildSortOptions";
import SelectInput from "../SelectInput";
import { sortLessons } from "../../helpers/recordKeeperHelper";
import { createResponsiveFilterLayout } from "../../helpers/responsiveFilterLayoutHelper";

const { Option } = Select;

const RecordKeeperFilters = ({ filters, handleChange, data, isMobile }) => {
  const ClassroomFilter = () => (
    <SelectInput
      placeholder="Classroom"
      value={filters.selectedClassroom}
      onChange={handleChange("selectedClassroom")}
      options={data.classrooms}
    />
  );

  const SubjectFilter = () => (
    <SelectInput
      placeholder="Subject"
      value={filters.selectedSubject}
      onChange={handleChange("selectedSubject")}
      options={data.subjects}
    />
  );

  const GradeFilter = () => (
    <Select
      placeholder="Grade"
      value={filters.selectedGrade}
      onChange={handleChange("selectedGrade")}
      allowClear
      style={{ width: "100%" }}
    >
      {data.grades && data.grades.length > 0 ? (
        data.grades.map((grade) => (
          <Option key={grade.id} value={grade.id}>
            {grade.name}
          </Option>
        ))
      ) : (
        <Option disabled>No Grades Available</Option>
      )}
    </Select>
  );

  const LevelsFilter = () => (
    <Select
      mode="multiple"
      placeholder="Levels"
      value={filters.selectedLevels}
      onChange={handleChange("selectedLevels")}
      options={data.masteryLevels.map((level) => ({
        label: `${level.level}. ${level.name}`,
        value: level.level,
      }))}
      style={{ width: "100%" }}
      allowClear
      showSearch={false}
    />
  );

  const LessonFilter = () => {
    const sortedLessons = sortLessons(Object.values(data.lessons));
    const selectedLesson = sortedLessons.find(
      (lesson) => String(lesson.id) === filters.selectedLessons
    );

    return (
      <Select
        showSearch
        placeholder="Lesson"
        value={selectedLesson ? selectedLesson.name : ""}
        onChange={(value, option) => {
          handleChange("selectedLessons")(value ? String(option.key) : "");
        }}
        optionFilterProp="label"
        filterOption={(input, option) =>
          option.label &&
          option.label.toLowerCase().includes(input.toLowerCase())
        }
        style={{ width: "100%" }}
        allowClear
        onClear={() => handleChange("selectedLessons")("")}
      >
        {sortedLessons.length > 0 ? (
          sortedLessons.map((lesson) => (
            <Option
              key={lesson.id}
              value={lesson.name}
              label={lesson.name}
              style={{ fontWeight: lesson.is_root ? "bold" : "normal" }}
            >
              {lesson.formatted_sequence} {lesson.name}
            </Option>
          ))
        ) : (
          <Option disabled>No Lessons Available</Option>
        )}
      </Select>
    );
  };

  const ChildrenFilter = () => {
    const gradeFilteredChildren = filters.selectedGrade
      ? Object.values(data.children).filter(
          (child) => child.grade_id === filters.selectedGrade
        )
      : Object.values(data.children);

    return (
      <Select
        mode="multiple"
        showSearch
        placeholder="Children"
        value={filters.selectedChildren}
        onChange={handleChange("selectedChildren")}
        optionFilterProp="value"
        filterOption={(input, option) =>
          option.value &&
          option.value.toLowerCase().includes(input.toLowerCase())
        }
        style={{ width: "100%" }}
        allowClear
      >
        {gradeFilteredChildren.length > 0 ? (
          gradeFilteredChildren.map((child) => (
            <Option key={`child - ${child.child_id}`} value={child.first_name}>
              {child.first_name}
            </Option>
          ))
        ) : (
          <Option disabled>No Children Available</Option>
        )}
      </Select>
    );
  };

  const filterComponents = [
    {
      component: <ClassroomFilter />,
      show: filters.shouldShowClassroomSelector,
      desktopSpan: 3,
      mobileSpan: 12,
    },
    {
      component: <SubjectFilter />,
      desktopSpan: 3,
      mobileSpan: 12,
    },
    {
      component: <LessonFilter />,
      desktopSpan: 7,
      mobileSpan: 12,
    },
    {
      component: <ChildrenFilter />,
      desktopSpan: 4,
      mobileSpan: 12,
    },
    {
      component: <LevelsFilter />,
      desktopSpan: 3,
      mobileSpan: 10,
    },
    {
      component: <GradeFilter />,
      desktopSpan: 2,
      mobileSpan: 8,
    },
    {
      component: (
        <ChildSortOptions
          value={filters.sortOption}
          onChange={handleChange("sortOption")}
        />
      ),
      desktopSpan: 2,
      mobileSpan: 6,
    },
  ];

  const { desktopLayout, mobileLayout } =
    createResponsiveFilterLayout(filterComponents);

  return <>{isMobile ? mobileLayout : desktopLayout}</>;
};

export default RecordKeeperFilters;
