import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import { Helmet } from "react-helmet-async";
import BookList from "../../components/library/BookList";
import ManageBookAuthorModal from "../../components/library/ManageBookAuthorModal";
import LoadingSpinner from "../../components/LoadingSpinner";
import {
  getAuthorById,
  getBooksByAuthor,
  mapBooksToAuthor,
  unmapBooksToAuthor,
} from "../../api/library/AuthorAPI";

const AuthorPage = () => {
  const { authorId } = useParams();
  const [author, setAuthor] = useState(null);
  const [books, setBooks] = useState([]);
  const [isBooksModalVisible, setIsBooksModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [loading, setLoading] = useState(true);

  const fetchAuthorAndBooks = useCallback(async () => {
    setLoading(true);
    try {
      const [authorData, booksData] = await Promise.all([
        getAuthorById(authorId),
        getBooksByAuthor(authorId),
      ]);
      setAuthor(authorData);
      setBooks(booksData);
    } catch (error) {
      console.error("Error fetching author and books:", error);
    } finally {
      setLoading(false);
    }
  }, [authorId]);

  useEffect(() => {
    fetchAuthorAndBooks();
  }, [fetchAuthorAndBooks]);

  const handleAddBooks = async (bookIds) => {
    try {
      await Promise.all(
        bookIds.map((bookId) => mapBooksToAuthor(bookId, authorId))
      );
      setIsBooksModalVisible(false);
      fetchAuthorAndBooks();
    } catch (error) {
      console.error("Error adding books to author:", error);
    }
  };

  const handleRemoveBooks = async (bookIds) => {
    try {
      await Promise.all(
        bookIds.map((bookId) => unmapBooksToAuthor(bookId, authorId))
      );
      setIsBooksModalVisible(false);
      fetchAuthorAndBooks();
    } catch (error) {
      console.error("Error removing books from author:", error);
    }
  };

  return (
    <div>
      <Helmet>
        <title>{author ? `${author.first_name} | Maidan` : "Loading..."}</title>
      </Helmet>
      {loading ? (
        <LoadingSpinner />
      ) : !author ? (
        <div>Author not found</div>
      ) : (
        <>
          <h2>
            {author.first_name} {author.last_name}
          </h2>
          <h3 style={{ marginLeft: 8 }}>Books</h3>
          <Button
            onClick={() => {
              setModalMode("add");
              setIsBooksModalVisible(true);
            }}
            style={{ marginLeft: 8 }}
          >
            Add Books
          </Button>
          <Button
            onClick={() => {
              setModalMode("remove");
              setIsBooksModalVisible(true);
            }}
            style={{ marginLeft: 8 }}
          >
            Remove Books
          </Button>
          <BookList books={books} />
          <ManageBookAuthorModal
            authorId={authorId}
            isVisible={isBooksModalVisible}
            onClose={() => setIsBooksModalVisible(false)}
            onAdd={modalMode === "add" ? handleAddBooks : handleRemoveBooks}
            mode={modalMode}
            existingBooks={books}
          />
        </>
      )}
    </div>
  );
};

export default AuthorPage;
