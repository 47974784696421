import { useState, useEffect } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import { Table, Card, Row, Col } from "antd";
import "../css/AttendanceTable.css";

const AttendanceTable = ({ attendanceData, userRole, print }) => {
  const [selectedStatus, setSelectedStatus] = useState(null);
  const presentData = attendanceData.filter((item) => item.present);
  const absentData = attendanceData.filter((item) => !item.present);
  let key = 1;

  const total = attendanceData.length;
  const data = [
    {
      name: "Present",
      value: presentData.length,
      percentage: total ? ((presentData.length / total) * 100).toFixed(1) : 0,
    },
    {
      name: "Absent",
      value: absentData.length,
      percentage: total ? ((absentData.length / total) * 100).toFixed(1) : 0,
    },
  ];

  useEffect(() => {
    if (print) {
      setSelectedStatus("absent");
    }
  }, [print]);

  const COLORS = ["green", "red"];

  // Custom tooltip
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          style={{
            backgroundColor: "#fff",
            padding: "5px 10px",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        >
          <p>{`${data.name}: ${data.value} (${data.percentage}%)`}</p>
        </div>
      );
    }
    return null;
  };

  // Custom legend
  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        style={{
          listStyle: "none",
          padding: 0,
          display: "flex",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: entry.color,
                display: "inline-block",
              }}
            />
            <span>{`${entry.value}: ${entry.payload.value} (${entry.payload.percentage}%)`}</span>
          </li>
        ))}
      </ul>
    );
  };

  const columns = [
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Class", dataIndex: "classroom_name", key: "classroom_name" },
    { title: "Recorded By", dataIndex: "recorded_by", key: "recorded_by" },
    ...(userRole !== "Guardian"
      ? [
          {
            title: "Notes",
            dataIndex: "note",
            key: "note",
          },
        ]
      : []),
  ];

  const overAllColumns = [
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Class", dataIndex: "classroom_name", key: "classroom_name" },
  ];

  const handlePieClick = (data) => {
    setSelectedStatus(data.name);
  };

  return (
    <div>
      <Row gutter={[16, 16]} style={{ display: "flex" }}>
        {/* <Col xs={24} sm={12} md={12} className="not-print">
          <Card
            title={`Overall Classes - ${attendanceData.length}`}
            style={{ height: "400px" }}
          >
            <Table
              dataSource={attendanceData.map((item) => ({
                key: `item-${key++}`,
                date: item.date.slice(0, 10),
                classroom_name: item.classroom_name,
              }))}
              columns={overAllColumns}
              pagination={false}
              style={{ height: "100%" }}
            />
          </Card>
        </Col> */}

        <Col xs={24} sm={12} md={12}>
          <Card
            title="Overview"
            style={{ height: "400px" }}
            className="piechart"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "400px",
              }}
            >
              <PieChart width={400} height={400}>
                <Pie
                  data={data}
                  cx="50%"
                  cy="45%"
                  innerRadius={60}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                  onClick={handlePieClick}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend content={<CustomLegend />} />
              </PieChart>
            </div>
          </Card>
          <div style={{ display: "none" }} className={print ? "print" : ""}>
            <h4>Total Classes: {attendanceData.length}</h4>
            <h4>Classes Present: {presentData ? presentData.length : 0}</h4>
            <h4>
              Attendance:{" "}
              {presentData && attendanceData
                ? ((presentData.length / attendanceData.length) * 100).toFixed(
                    1
                  )
                : "--"}
              %
            </h4>
          </div>
        </Col>
      </Row>
      {selectedStatus && (
        <Card
          title={`Classes ${selectedStatus}: ${selectedStatus === "Present" ? presentData.length : absentData.length}`}
          style={{ marginTop: "20px" }}
          className={absentData?.length > 0 ? "" : "not-print"}
        >
          <Table
            dataSource={
              selectedStatus === "Present"
                ? presentData.map((item) => ({
                    key: `item-${item.classroom_id}`,
                    date: item.date.slice(0, 10),
                    classroom_name: item.classroom_name,
                    recorded_by:
                      item.recorded_by_first_name +
                      " " +
                      item.recorded_by_last_name,
                    note: item.note,
                  }))
                : absentData.map((item) => ({
                    key: `item-${item.classroom_id}`,
                    date: item.date.slice(0, 10),
                    classroom_name: item.classroom_name,
                    recorded_by:
                      item.recorded_by_first_name +
                      " " +
                      item.recorded_by_last_name,
                    note: item.note,
                  }))
            }
            columns={columns}
            pagination={false}
          />
        </Card>
      )}
    </div>
  );
};

export default AttendanceTable;
