import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Button, Spin, message, Upload, Image } from "antd";
import { TwitterPicker } from "react-color";
import { formFocusHelper } from "../helpers/formFocusHelper";
import { validateFile } from "../helpers/imageHelper";
import { UploadOutlined } from "@ant-design/icons";

const MasteryLevelEditForm = ({ masteryLevels, masteryLevel, onFinish, title }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#ffffff");
  const [image, setImage] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    return () => formFocusHelper(ref); 
  }, []);

  const handleImageChange = (file) => {
    setImage(file);
  };

  const handleUpload = async () => {
    if (!image) return null;
    return image;
  };

  useEffect(() => {
    if (masteryLevel) {
      form.setFieldsValue({
        level: masteryLevel.level,
        name: masteryLevel.name,
        code: masteryLevel.code,
        color: masteryLevel.color,
      });
      setColor(masteryLevel.color || "#ffffff");
    }
  }, [masteryLevel, form]);

  const handleSubmit = async (values) => {
    const s3ImageUrl = await handleUpload();
    values.cover_image_url= image ? s3ImageUrl : masteryLevel.cover_image_url;
    setLoading(true);
    try {
      await onFinish({ ...values, color });
    } finally {
      setLoading(false);
    }
  };

  const handleColorChange = (color) => {
    setColor(color.hex);
  };

  const validateLevel = (_, value) => {  
    if (masteryLevels.some((item) => item.level === Number(value) && item.id !== masteryLevel?.id)) {
      return Promise.reject(new Error("This level already exists. Please choose a different one."));
    }
    return Promise.resolve();
  };
  const validateName = (_, value) => {  
    if (masteryLevels.some((item) => item.name === value && item.id !== masteryLevel?.id)) {
      return Promise.reject(new Error("This name already exists. Please choose a different one."));
    }
    return Promise.resolve();
  };
  const validateCode = (_, value) => {  
    if (masteryLevels.some((item) => item.code === value && item.id !== masteryLevel?.id)) {
      return Promise.reject(new Error("This code already exists. Please choose a different one."));
    }
    if (value.length>6) {
      return Promise.reject(new Error("Code is too long."));
    }
    return Promise.resolve();
  };

  const beforeUpload = (file) => {
    const { isValid, message: errorMessage } = validateFile(file, 2); // Use the helper function
    if (!isValid) {
      message.error(errorMessage);
      return Upload.LIST_IGNORE;
    }

    handleImageChange(file);
    return false; // Prevent default upload behavior
  };

  return (
    <Spin spinning={loading}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <div style={{ display: "flex", width: "100%" }}>
          <Form.Item
            name="cover_image_url"
            label="cover_image_url"
            rules={[
              {
                required: !masteryLevel.level,
                message: "Please upload a cover image.",
              },
            ]}
            style={{ width: "180px" }}
          >
            <Upload
              listType="picture"
              maxCount={1}
              beforeUpload={beforeUpload}
            >
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Form.Item>
          {masteryLevel.cover_image_url && (
            <Image
              src={masteryLevel.cover_image_url}
              style={{ width: "100px", marginTop: "10px", marginLeft: "50px" }}
            />
          )}
        </div>
        <Form.Item
          name="level"
          label="Level"
          rules={[
            { required: true, message: "Please enter the level" },
            { validator: validateLevel }
          ]}
        >
          <Input type="number" ref={ref}/>
        </Form.Item>

        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, message: "Please input the name." },
            { max: 30, message: "Name cannot exceed 30 characters." },
            { validator: validateName }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="code"
          label="Code"
          rules={[
            { required: true, message: "Please enter the code" },
            { validator: validateCode }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="color"
          label="Color"
          rules={[{ required: true, message: "Please select a color" }]}
        >
          <TwitterPicker color={color} onChangeComplete={handleColorChange} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {title}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default MasteryLevelEditForm;
