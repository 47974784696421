import { useEffect, useRef } from "react";

// Helper function for deep equality check
const deepEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

const useScrollTableToTop = (dependencies) => {
  const previousDependencies = useRef(dependencies); // Store the previous dependencies

  useEffect(() => {
    // Only trigger scroll when the actual filter values change
    if (!deepEqual(previousDependencies.current, dependencies)) {
      const tableBody = document.querySelector(".ant-table-body");

      if (tableBody) {
        tableBody.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }

      // Update the previous dependencies after the scroll happens
      previousDependencies.current = dependencies;
    }
  }, [dependencies]); // Re-run effect when dependencies change
};

export default useScrollTableToTop;
