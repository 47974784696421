// src/config/sidebarItems.js
import {
  BarChartOutlined,
  SmileOutlined,
  RiseOutlined,
  CalendarOutlined,
  FileTextOutlined,
  UsergroupAddOutlined,
  SafetyCertificateOutlined,
  ReadOutlined,
  ApartmentOutlined,
  SolutionOutlined,
  ExperimentOutlined,
  ToolOutlined,
  BankOutlined,
  IdcardOutlined,
  UserOutlined,
  UnorderedListOutlined,
  GroupOutlined,
  BookOutlined,
} from "@ant-design/icons";

export const sidebarItems = [
  {
    key: "dashboard",
    label: "Dashboard",
    icon: <BarChartOutlined />,
    path: "/",
  },
  {
    key: "children",
    label: "Children",
    icon: <SmileOutlined />,
    path: "/children",
  },
  {
    key: "record-keeper",
    label: "Record Keeper",
    icon: <RiseOutlined />,
    path: "/record-keeper",
  },
  {
    key: "development-record",
    label: "Dev Record",
    icon: <BookOutlined />,
    path: "/development-record",
  },
  {
    key: "progress-overview",
    label: "Progress Overview",
    icon: <SolutionOutlined />,
    path: "/progress-overview",
  },
  {
    key: "presented-lessons",
    label: "Presented Lessons",
    icon: <ExperimentOutlined />,
    path: "/presented-lessons",
  },
  {
    key: "record-attendance",
    label: "Attendance",
    icon: <CalendarOutlined />,
    path: "/record-attendance",
  },
  {
    key: "attendance-report",
    label: "Attendance Report",
    icon: <FileTextOutlined />,
    path: "/attendance-report",
  },
  {
    key: "lesson-editor",
    label: "Lesson Editor",
    icon: <ApartmentOutlined />,
    path: "/lesson-editor",
  },
  {
    key: "guardians",
    label: "Guardians",
    icon: <UsergroupAddOutlined />,
    path: "/guardians",
  },
  {
    key: "staff-members",
    label: "Staff",
    icon: <IdcardOutlined />,
    path: "/staff-members",
  },
  {
    key: "security-guards",
    label: "Security Report",
    icon: <SafetyCertificateOutlined />,
    path: "/security-guards",
  },
  {
    key: "books",
    label: "Books",
    icon: <BookOutlined />,
    path: "/library/books",
  },
  {
    key: "authors",
    label: "Authors",
    icon: <UserOutlined />,
    path: "/library/authors",
  },
  {
    key: "collections",
    label: "Collections",
    icon: <UnorderedListOutlined />,
    path: "/library/collections",
  },
  {
    key: "publishers",
    label: "Publishers",
    icon: <IdcardOutlined />,
    path: "/library/publishers",
  },
  {
    key: "categories",
    label: "Categories",
    icon: <GroupOutlined />,
    path: "/library/categories",
  },
  {
    key: "classrooms",
    label: "Classrooms",
    icon: <ReadOutlined />,
    path: "/classrooms",
  },
  {
    key: "grades",
    label: "Grades",
    icon: <ReadOutlined />,
    path: "/grades",
  },
  {
    key: "school-info",
    label: "School Info",
    icon: <BankOutlined />,
    path: "/school-info",
  },
  {
    key: "settings",
    label: "Settings",
    icon: <ToolOutlined />,
    path: "/settings",
  },
];
