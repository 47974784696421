import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Drawer, Button, Input } from "antd";
import PublisherEditForm from "./PublisherEditForm";
import {
  createPublisher,
  updatePublisher,
} from "../../api/library/PublisherAPI";
import requestHandler from "../../utils/requestHandler";
import { useAbility } from "../../hooks/useAbility";
import { EditOutlined } from "@ant-design/icons";
import CustomTable from "../CustomTable";

const { Search } = Input;

const PublisherList = ({
  publishers,
  refreshPublishers,
  showNewButton = false,
  showSearchBar = false,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedPublisher, setSelectedPublisher] = useState(null);
  const ability = useAbility();

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter publishers while handling cases where the publisher object might be null
  const filteredPublishers = publishers
    .filter((publisher) => publisher && publisher.name) // Ensure publisher is not null and has a name
    .filter((publisher) =>
      publisher.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const handleRowClick = (record) => {
    setSelectedPublisher(record);
    setIsDrawerOpen(true);
  };

  const handleNewPublisher = () => {
    setSelectedPublisher({});
    setIsDrawerOpen(true);
  };

  const handleFormFinish = async (publisherData) => {
    if (selectedPublisher && selectedPublisher.id) {
      await requestHandler.execute(
        updatePublisher(selectedPublisher.id, publisherData),
        "Publisher updated.",
        "Failed to update publisher"
      );
    } else {
      await requestHandler.execute(
        createPublisher(publisherData),
        "New publisher created.",
        "Failed to create new publisher"
      );
    }
    setIsDrawerOpen(false);
    refreshPublishers();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/library/publishers/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text) => text || "-", // Display "-" if address is null or undefined
    },
    {
      title: "Contact Info",
      dataIndex: "contact_info",
      key: "contact_info",
      render: (text) => text || "-", // Display "-" if contact info is null or undefined
    },
    {
      key: "action",
      width: 50,
      render: (_, record) => (
        <Button
          icon={<EditOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            handleRowClick(record);
          }}
          disabled={!ability.can("update", "publishers")}
        />
      ),
    }
  ];

  return (
    <div className="table-view">
      {showNewButton && (
        <Button
          type="default"
          onClick={handleNewPublisher}
          style={{ marginBottom: 16 }}
        >
          New Publisher
        </Button>
      )}
      {showSearchBar && (
        <Search
          placeholder="Search by name"
          allowClear
          onChange={handleSearch}
          style={{ marginBottom: 16 }}
        />
      )}
      <CustomTable columns={columns} dataSource={filteredPublishers} />
      <Drawer
        width={400}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedPublisher(null);
        }}
        open={isDrawerOpen}
        styles={{ body: {paddingBottom: 80} }}
      >
        {isDrawerOpen && (
          <PublisherEditForm
            publisher={selectedPublisher}
            onFinish={handleFormFinish}
            refreshPublishers={refreshPublishers}
            title={
              selectedPublisher && selectedPublisher.id
                ? "Edit Publisher"
                : "New Publisher"
            }
          />
        )}
      </Drawer>
    </div>
  );
};

export default PublisherList;
