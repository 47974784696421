import React from "react";
import { Card } from "antd";
import { Link } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import { getFullName } from "../helpers/childHelper";
import "../css/ClassroomCardList.css";

const ClassroomCardList = ({ classrooms, onClassroomClick, ability }) => {
  return (
    <div className="classroom-card-list">
      {classrooms.map((classroom) => (
        <Card key={classroom.id} className="classroom-card">
          <div className="classroom-card-content">
            <div className="classroom-info">
              <div className="classroom-header">
                <Link
                  to={`/classrooms/${classroom.id}`}
                  className="classroom-card-name"
                >
                  {classroom.name}
                </Link>
                <div className="classroom-actions">
                  {ability.can("update", "classroom") && (
                    <EditOutlined
                      className="edit-icon"
                      onClick={() => onClassroomClick(classroom)}
                    />
                  )}
                </div>
              </div>
              <div className="classroom-details">
                <div className="classroom-child-count">
                  Children: {classroom.child_count}
                </div>
                <div className="classroom-teachers">
                  Teachers:{" "}
                  {classroom.teachers && classroom.teachers.length > 0
                    ? classroom.teachers
                        .map((teacher) => getFullName(teacher))
                        .join(", ")
                    : "No teachers"}
                </div>
                <div className="classroom-grades">
                  Grades:{" "}
                  {classroom.grades && classroom.grades.length > 0
                    ? classroom.grades.map((grade) => grade.name).join(", ")
                    : "No grades"}
                </div>
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default ClassroomCardList;
