import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { getAuthors } from "../../api/library/AuthorAPI"; // Import the API call for fetching authors

const { Option } = Select;

const AuthorsDropdown = ({ value = [], onChange }) => {
  const [authors, setAuthors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const fetchAuthors = async () => {
      setLoading(true);
      try {
        const authorsList = await getAuthors(); // Fetch authors from the API
        setAuthors(authorsList);
      } catch (error) {
        console.error("Error fetching authors:", error);
      }
      setLoading(false);
    };

    fetchAuthors();
  }, []);

  const handleSearch = (searchText) => {
    setInputValue(searchText);
  };

  const handleSelect = (selectedValue) => {
    const [first_name, last_name] = selectedValue.split(" ").length > 1
      ? selectedValue.split(" ")
      : [selectedValue, ""];

    const authorExists = authors.some((author) =>
      last_name.length > 0
        ? author.first_name === first_name && author.last_name === last_name
        : author.first_name === first_name
    );

    if (!authorExists && !value.includes(selectedValue)) {
      const newAuthor = { id: selectedValue, first_name, last_name };
      setAuthors((prevAuthors) => [...prevAuthors, newAuthor]);
      onChange([...value, selectedValue]); 
    } else if (!value.includes(selectedValue)) {
      onChange([...value, selectedValue]);
    }
  };

  return (
    <Select
      mode="multiple" // Allow multiple selections
      value={value}
      onChange={onChange}
      onSearch={handleSearch}
      onSelect={handleSelect}
      showSearch
      placeholder="Select Authors"
      loading={loading}
      optionLabelProp="label"
      filterOption={(input, option) =>
        option.label.toLowerCase().includes(input.toLowerCase())
      }
    >
      {authors.map((author) => (
        <Option
          key={author.last_name ? `${author.first_name} ${author.last_name}` : `${author.first_name}`}
          value={author.last_name ? `${author.first_name} ${author.last_name}` : `${author.first_name}`}
          label={author.last_name ? `${author.first_name} ${author.last_name}` : `${author.first_name}`}
        >
          {author.first_name} {author.last_name}
        </Option>
      ))}
      {/* Allow user to add a new option dynamically */}
      {inputValue && !authors.some((author) => `${author.first_name} ${author.last_name}` === inputValue) && !value.includes(inputValue) && (
        <Option key={inputValue} value={inputValue} label={`Add "${inputValue}"`}>
          {`Add "${inputValue}"`}
        </Option>
      )}
    </Select>
  );
};

export default AuthorsDropdown;
