import React, { useState } from "react";
import { Table } from "antd";

const CustomTable = ({
  columns,
  dataSource,
  rowKey = "id",
  pageSize = 20,
  showSerialNo = true,
  enablePagination = false,
  enableScroll = true,
  alternateRowColors = true,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const modifiedColumns = showSerialNo
    ? [
        {
          key: "sl_no",
          render: (text, record, index) =>
            (currentPage - 1) * pageSize + index + 1,
          width: 50,
        },
        ...columns,
      ]
    : columns;

  const rowClassName = alternateRowColors
    ? (record, index) => (index % 2 === 0 ? "row-even" : "row-odd")
    : undefined;

  return (
    <Table
      className="custom-table"
      columns={modifiedColumns}
      dataSource={dataSource}
      rowKey={rowKey}
      rowClassName={rowClassName}
      pagination={
        enablePagination && dataSource.length > pageSize
          ? {
              pageSize,
              onChange: (page) => setCurrentPage(page),
            }
          : false
      }
      scroll={enableScroll ? { x: "max-content", y: 560 } : undefined}
      bordered
    />
  );
};

export default CustomTable;
