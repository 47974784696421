import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import { Helmet } from "react-helmet-async";
import BookList from "../../components/library/BookList";
import ManageBookCollectionModal from "../../components/library/ManageBookCollectionModal";
import LoadingSpinner from "../../components/LoadingSpinner";
import {
  getCollectionById,
  getBooksByCollection,
  mapBooksToCollection,
  unmapBooksToCollection,
} from "../../api/library/CollectionAPI";

const CollectionPage = () => {
  const { collectionId } = useParams();
  const [collection, setCollection] = useState(null);
  const [books, setBooks] = useState([]);
  const [isBooksModalVisible, setIsBooksModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCollectionAndBooks = useCallback(async () => {
    setLoading(true);
    try {
      const [collectionData, booksData] = await Promise.all([
        getCollectionById(collectionId),
        getBooksByCollection(collectionId),
      ]);
      setCollection(collectionData);
      setBooks(booksData);
    } catch (error) {
      console.error("Error fetching collection and books:", error);
      setError("Failed to load collection data");
    } finally {
      setLoading(false);
    }
  }, [collectionId]);

  useEffect(() => {
    fetchCollectionAndBooks();
  }, [fetchCollectionAndBooks]);

  const handleAddBooks = async (bookIds) => {
    try {
      await Promise.all(
        bookIds.map((bookId) => mapBooksToCollection(bookId, collectionId))
      );
      setIsBooksModalVisible(false);
      fetchCollectionAndBooks();
    } catch (error) {
      console.error("Error adding books to collection:", error);
      setError("Failed to add books to collection");
    }
  };

  const handleRemoveBooks = async (bookIds) => {
    try {
      await Promise.all(
        bookIds.map((bookId) => unmapBooksToCollection(bookId, collectionId))
      );
      setIsBooksModalVisible(false);
      fetchCollectionAndBooks();
    } catch (error) {
      console.error("Error removing books from collection:", error);
      setError("Failed to remove books from collection");
    }
  };

  return (
    <div>
      <Helmet>
        <title>
          {collection ? `${collection.name} | Maidan` : "Loading..."}
        </title>
      </Helmet>
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <div>{error}</div>
      ) : !collection ? (
        <div>Collection not found</div>
      ) : (
        <>
          <h2>{collection.name}</h2>
          <h3 style={{ marginLeft: 8 }}>Description</h3>
          <div style={{ marginLeft: 8 }}>{collection.description}</div>
          <h3 style={{ marginLeft: 8 }}>Books</h3>
          <Button
            onClick={() => {
              setModalMode("add");
              setIsBooksModalVisible(true);
            }}
            style={{ marginLeft: 8 }}
          >
            Add Books
          </Button>
          <Button
            onClick={() => {
              setModalMode("remove");
              setIsBooksModalVisible(true);
            }}
            style={{ marginLeft: 8 }}
          >
            Remove Books
          </Button>
          <BookList books={books} />
          <ManageBookCollectionModal
            collectionId={collectionId}
            isVisible={isBooksModalVisible}
            onClose={() => setIsBooksModalVisible(false)}
            onAdd={modalMode === "add" ? handleAddBooks : handleRemoveBooks}
            mode={modalMode}
            existingBooks={books}
          />
        </>
      )}
    </div>
  );
};

export default CollectionPage;
