import React, { useState, Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { Select } from "antd";
import GuardAttendanceDailyReport from "./GuardAttendanceDailyReport";
import GuardAttendanceMonthlyReport from "../../components/guards/GuardAttendanceMonthlyReport";
import LoadingSpinner from "../../components/LoadingSpinner";
import "../../css/guards/GuardAttendanceReportPage.css";

const { Option } = Select;

const GuardAttendanceReportPage = () => {
  const [reportType, setReportType] = useState("daily");

  const handleReportTypeChange = (value) => {
    setReportType(value);
  };

  return (
    <div>
      <Helmet>
        <title>Security Report | Maidan</title>
      </Helmet>

      <h1 className="page-heading">Security Report</h1>

      <div>
        <Select
          value={reportType}
          onChange={handleReportTypeChange}
          style={{ width: 200 }}
        >
          <Option value="daily">Daily Report</Option>
          <Option value="monthly">Monthly Report</Option>
        </Select>
      </div>

      <Suspense fallback={<LoadingSpinner />}>
        <div>
          {reportType === "daily" ? (
            <GuardAttendanceDailyReport />
          ) : (
            <GuardAttendanceMonthlyReport />
          )}
        </div>
      </Suspense>
    </div>
  );
};

export default GuardAttendanceReportPage;
