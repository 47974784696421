import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import { Helmet } from "react-helmet-async";
import CategoryList from "../../components/library/CategoryList";
import AuthorList from "../../components/library/AuthorList";
import PublisherList from "../../components/library/PublisherList";
import CollectionList from "../../components/library/CollectionList";
import ManageCategoryBookModal from "../../components/library/ManageCategoryBookModal";
import ManageAuthorBookModal from "../../components/library/ManageAuthorBookModal";
import ManageCollectionBookModal from "../../components/library/ManageCollectionBookModal";
import LoadingSpinner from "../../components/LoadingSpinner";
import {
  getBookById,
  getCategoriesByBook,
  getAuthorsByBook,
  getPublisherByBook,
  getCollectionsByBook,
  mapCategoriesToBook,
  unmapCategoriesToBook,
  mapAuthorsToBook,
  unmapAuthorsToBook,
  mapCollectionsToBook,
  unmapCollectionsToBook,
} from "../../api/library/BookAPI";

const BookPage = () => {
  const { bookId } = useParams();
  const [loading, setLoading] = useState(true);
  const [book, setBook] = useState(null);
  const [categories, setCategories] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [publisher, setPublisher] = useState([]);
  const [collections, setCollections] = useState([]);
  const [isCategoriesModalVisible, setIsCategoriesModalVisible] =
    useState(false);
  const [isAuthorsModalVisible, setIsAuthorsModalVisible] = useState(false);
  const [isCollectionsModalVisible, setIsCollectionsModalVisible] =
    useState(false);
  const [modalMode, setModalMode] = useState("add");

  const fetchBookData = useCallback(async () => {
    setLoading(true);
    try {
      const [
        bookData,
        categoriesData,
        authorsData,
        publisherData,
        collectionsData,
      ] = await Promise.all([
        getBookById(bookId),
        getCategoriesByBook(bookId),
        getAuthorsByBook(bookId),
        getPublisherByBook(bookId),
        getCollectionsByBook(bookId),
      ]);
      setBook(bookData);
      setCategories(categoriesData);
      setAuthors(authorsData);
      setPublisher([publisherData]);
      setCollections(collectionsData);
    } catch (error) {
      console.error("Error fetching book data:", error);
    } finally {
      setLoading(false);
    }
  }, [bookId]);

  useEffect(() => {
    fetchBookData();
  }, [fetchBookData]);

  const handleAddCategories = async (categoryIds) => {
    try {
      await Promise.all(
        categoryIds.map((categoryId) => mapCategoriesToBook(bookId, categoryId))
      );
      setIsCategoriesModalVisible(false);
      fetchBookData();
    } catch (error) {
      console.error("Error adding categories to book:", error);
    }
  };

  const handleRemoveCategories = async (categoryIds) => {
    try {
      await Promise.all(
        categoryIds.map((categoryId) =>
          unmapCategoriesToBook(bookId, categoryId)
        )
      );
      setIsCategoriesModalVisible(false);
      fetchBookData();
    } catch (error) {
      console.error("Error removing categories from book:", error);
    }
  };

  const handleAddAuthors = async (authorIds) => {
    try {
      await Promise.all(
        authorIds.map((authorId) => mapAuthorsToBook(bookId, authorId))
      );
      setIsAuthorsModalVisible(false);
      fetchBookData();
    } catch (error) {
      console.error("Error adding authors to book:", error);
    }
  };

  const handleRemoveAuthors = async (authorIds) => {
    try {
      await Promise.all(
        authorIds.map((authorId) => unmapAuthorsToBook(bookId, authorId))
      );
      setIsAuthorsModalVisible(false);
      fetchBookData();
    } catch (error) {
      console.error("Error removing authors from book:", error);
    }
  };

  const handleAddCollections = async (collectionIds) => {
    try {
      await Promise.all(
        collectionIds.map((collectionId) =>
          mapCollectionsToBook(bookId, collectionId)
        )
      );
      setIsCollectionsModalVisible(false);
      fetchBookData();
    } catch (error) {
      console.error("Error adding collections to book:", error);
    }
  };

  const handleRemoveCollections = async (collectionIds) => {
    try {
      await Promise.all(
        collectionIds.map((collectionId) =>
          unmapCollectionsToBook(bookId, collectionId)
        )
      );
      setIsCollectionsModalVisible(false);
      fetchBookData();
    } catch (error) {
      console.error("Error removing collections from book:", error);
    }
  };

  return (
    <div>
      <Helmet>
        <title>{book ? `${book.title} | Maidan` : "Loading..."}</title>
      </Helmet>
      {loading ? (
        <LoadingSpinner />
      ) : !book ? (
        <div>Book not found</div>
      ) : (
        <>
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <h2 style={{ fontSize: "24px", color: "#333", marginTop: "0" }}>
              {book.title}
            </h2>
            {book.cover_image_url && (
              <img
                src={book.cover_image_url}
                alt={book.title}
                style={{
                  width: "150px",
                  height: "auto",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              />
            )}
          </div>
          <h3 style={{ marginLeft: 8 }}>Description</h3>
          <div style={{ marginLeft: 8 }}>{book.description}</div>
          <h3 style={{ marginLeft: 8 }}>Categories</h3>
          <Button
            onClick={() => {
              setModalMode("add");
              setIsCategoriesModalVisible(true);
            }}
            style={{ marginLeft: 8 }}
          >
            Add Categories
          </Button>
          <Button
            onClick={() => {
              setModalMode("remove");
              setIsCategoriesModalVisible(true);
            }}
            style={{ marginLeft: 8 }}
          >
            Remove Categories
          </Button>
          <CategoryList categories={categories} />
          <ManageCategoryBookModal
            bookId={bookId}
            isVisible={isCategoriesModalVisible}
            onClose={() => setIsCategoriesModalVisible(false)}
            onAdd={
              modalMode === "add" ? handleAddCategories : handleRemoveCategories
            }
            mode={modalMode}
            existingCategories={categories}
          />

          <h3 style={{ marginLeft: 8 }}>Authors</h3>
          <Button
            onClick={() => {
              setModalMode("add");
              setIsAuthorsModalVisible(true);
            }}
            style={{ marginLeft: 8 }}
          >
            Add Authors
          </Button>
          <Button
            onClick={() => {
              setModalMode("remove");
              setIsAuthorsModalVisible(true);
            }}
            style={{ marginLeft: 8 }}
          >
            Remove Authors
          </Button>
          <AuthorList authors={authors} />
          <ManageAuthorBookModal
            bookId={bookId}
            isVisible={isAuthorsModalVisible}
            onClose={() => setIsAuthorsModalVisible(false)}
            onAdd={modalMode === "add" ? handleAddAuthors : handleRemoveAuthors}
            mode={modalMode}
            existingAuthors={authors}
          />

          <h3 style={{ marginLeft: 8 }}>Collections</h3>
          <Button
            onClick={() => {
              setModalMode("add");
              setIsCollectionsModalVisible(true);
            }}
            style={{ marginLeft: 8 }}
          >
            Add Collections
          </Button>
          <Button
            onClick={() => {
              setModalMode("remove");
              setIsCollectionsModalVisible(true);
            }}
            style={{ marginLeft: 8 }}
          >
            Remove Collections
          </Button>
          <CollectionList collections={collections} />
          <ManageCollectionBookModal
            bookId={bookId}
            isVisible={isCollectionsModalVisible}
            onClose={() => setIsCollectionsModalVisible(false)}
            onAdd={
              modalMode === "add"
                ? handleAddCollections
                : handleRemoveCollections
            }
            mode={modalMode}
            existingCollections={collections}
          />

          <h3 style={{ marginLeft: 8 }}>Publisher</h3>
          <PublisherList publishers={publisher} />
        </>
      )}
    </div>
  );
};

export default BookPage;
