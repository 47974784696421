import React from "react";
import { Card } from "antd";
import { CheckCircleFilled, QuestionCircleOutlined } from "@ant-design/icons";
import { getFullName } from "../../helpers/childHelper";

const MasteryIcon = ({ isPresented, onClick }) =>
  isPresented ? (
    <CheckCircleFilled
      style={{ color: "green", marginRight: 8, cursor: "pointer" }}
      onClick={onClick}
    />
  ) : (
    <QuestionCircleOutlined
      style={{ marginRight: 8, cursor: "pointer" }}
      onClick={onClick}
    />
  );

const MyPlannedActivitiesCardList = ({
  plannedActivities,
  handleToggleMasteryLevel,
  view,
}) => {
  if (
    !plannedActivities ||
    Object.keys(plannedActivities.activities).length === 0
  ) {
    return <p>No planned activities found.</p>;
  }

  if (view === "activity") {
    return (
      <div className="mobile-activity-card-list">
        {Object.entries(plannedActivities.activities).map(
          ([lessonId, lessonData]) => (
            <Card key={lessonId} style={{ marginBottom: "16px" }}>
              <h3>{lessonData.name}</h3>
              {lessonData.parent_lesson_name && (
                <p>{lessonData.parent_lesson_name}</p>
              )}
              <div>
                {lessonData.children.map((child) => (
                  <div key={child.child_id} style={{ marginBottom: "8px" }}>
                    <MasteryIcon
                      isPresented={
                        child.mastery_level_id ===
                        plannedActivities.presented_mastery_level_id
                      }
                      onClick={() =>
                        handleToggleMasteryLevel(
                          child.child_id,
                          lessonId,
                          child.mastery_level_id
                        )
                      }
                    />
                    {getFullName(child)}
                  </div>
                ))}
              </div>
            </Card>
          )
        )}
      </div>
    );
  }

  const childMap = new Map();
  Object.entries(plannedActivities.activities).forEach(
    ([lessonId, lessonData]) => {
      lessonData.children.forEach((child) => {
        const childKey = `${child.child_id}`;
        if (!childMap.has(childKey)) {
          childMap.set(childKey, {
            key: childKey,
            childName: `${getFullName(child)}`,
            activities: [],
          });
        }
        childMap.get(childKey).activities.push({
          name: lessonData.name,
          parentLessonName: lessonData.parent_lesson_name,
          mastery_level_id: child.mastery_level_id,
          child_id: child.child_id,
          lesson_id: lessonId,
        });
      });
    }
  );

  return (
    <div className="mobile-activity-card-list">
      {[...childMap.values()].map((child) => (
        <Card key={child.key} style={{ marginBottom: "16px" }}>
          <h3>{child.childName}</h3>
          <div>
            {child.activities.map((activity, index) => (
              <div
                key={`${child.key}-${activity.lesson_id}`}
                style={{ marginBottom: "8px" }}
              >
                <MasteryIcon
                  isPresented={
                    activity.mastery_level_id ===
                    plannedActivities.presented_mastery_level_id
                  }
                  onClick={() =>
                    handleToggleMasteryLevel(
                      activity.child_id,
                      activity.lesson_id,
                      activity.mastery_level_id
                    )
                  }
                />
                {activity.name}
                {activity.parentLessonName && (
                  <div className="parent-lesson-name">
                    {activity.parentLessonName}
                  </div>
                )}
              </div>
            ))}
          </div>
        </Card>
      ))}
    </div>
  );
};

export default MyPlannedActivitiesCardList;
