// client/src/api/MembershipsAPI.js
import axios from "axios";

const baseURL = "/memberships";

const api = axios.create({
  baseURL,
});

export const toggleMembershipActiveStatus = (membershipId) => {
  return api.put(`/memberships/${membershipId}/toggle-active`);
};

export const acceptInvitation = (id) => {
  return api.post(`/accept-invitation/${id}`);
};

export const rejectInvitation = (id) => {
  return api.post(`/reject-invitation/${id}`);
};

export const updateMembershipProfilePhoto = (membershipId, profilePhoto) => {
  const formData = new FormData();

  const file = new File([profilePhoto], `profile-photo-${Date.now()}.png`, {
    type: profilePhoto.type,
  });

  formData.append("profile_photo", file);

  return axios
    .post(`${baseURL}/${membershipId}/profile-photo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error updating profile photo:", error);
      throw error;
    });
};

export const sendWelcomeEmail = async (first_name, email) => {
  try {
    const response = await api.post("/send-welcome-email", {
      first_name,
      email,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error("An unexpected error occurred. Please try again.");
    }
  }
};
