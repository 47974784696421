import React, { useEffect, useRef } from "react";
import { Form, Input, Button, Typography } from "antd";
import { formFocusHelper } from "../../helpers/formFocusHelper";

const { Title } = Typography;

const AuthorEditForm = ({ author = {}, onFinish, refreshAuthors, title }) => {
  const ref = useRef(null);

  useEffect(() => {
    return () => formFocusHelper(ref); 
  }, []);

  const handleFinish = (authorData) => {
    onFinish(authorData)
      .then(() => {
        refreshAuthors();
      })
      .catch((error) => {
        console.error("Error handling author:", error);
      });
  };

  return (
    <>
      <Title level={2}>{title}</Title>
      <Form
        initialValues={author}
        onFinish={handleFinish}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          name="first_name"
          label="First Name"
          rules={[
            { required: true, message: "Please input the first name." },
            { pattern: /^[A-Za-z\s]+$/, message: "First name must contain only letters." }
          ]}
        >
          <Input ref={ref}/>
        </Form.Item>

        <Form.Item
          name="last_name"
          label="Last Name"
          rules={[
            { pattern: /^[A-Za-z\s]*$/, message: "Last name must contain only letters." }
          ]}>
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {author.id ? "Update Author" : "Create Author"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AuthorEditForm;
