import React, { useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import TeacherPresentationsList from "../components/record-keeper/TeacherPresentationsList";
import LoadingSpinner from "../components/LoadingSpinner";
import MembershipInfo from "../components/MembershipInfo";
import { getEmployeeById } from "../api/EmployeeAPI";
import "../css/EmployeePage.css";

const EmployeePage = () => {
  const { employeeId } = useParams();
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchEmployee = useCallback(async () => {
    try {
      const data = await getEmployeeById(employeeId);
      setEmployee(data);
    } catch (error) {
      console.error("Error fetching employee:", error);
    } finally {
      setLoading(false);
    }
  }, [employeeId]);

  useEffect(() => {
    fetchEmployee();
  }, [fetchEmployee]);

  if (loading) return <LoadingSpinner />;

  return (
    <div className="employee-page">
      <Helmet>
        <title>
          {employee
            ? `${employee.first_name} ${employee.last_name} | Maidan`
            : "Staff Member | Maidan"}
        </title>
      </Helmet>

      <MembershipInfo
        membership={employee}
        showPhone={true}
        showEmail={true}
        id={employee.eid}
        classrooms={employee.classrooms}
      />

      {employee.role === "Teacher" && (
        <>
          <h3>Presentations</h3>
          <TeacherPresentationsList membershipId={employee.membership_id} />
        </>
      )}
    </div>
  );
};

export default EmployeePage;
