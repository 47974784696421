// src/utils/requestHandler.js
import Bugsnag from "@bugsnag/js";
import { notification } from "antd";

const DEFAULT_DURATION = 3;

const requestHandler = {
  success: (msg, options = {}) => {
    if (msg) {
      notification.success({
        description: msg,
        duration: DEFAULT_DURATION,
        ...options,
      });
    }
  },

  warning: (msg, options = {}) => {
    notification.warning({
      description: msg,
      duration: DEFAULT_DURATION,
      ...options,
    });
  },

  error: (error, msg = "An error occurred", options = {}) => {
    Bugsnag.notify(error);

    if (process.env.NODE_ENV === "development") {
      console.log("Error details:", error);
    }

    notification.error({
      description: msg,
      duration: DEFAULT_DURATION,
      ...options,
    });
  },

  // Handle the result of a promise
  execute: async (promise, successMsg, errorMsg) => {
    try {
      const result = await promise;
      requestHandler.success(successMsg);
      return result;
    } catch (error) {
      requestHandler.error(error, errorMsg);
      return null;
    }
  },
};

export default requestHandler;
