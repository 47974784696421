import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getBooksById, getPublisherById } from "../../api/library/PublisherAPI";
import BookList from "../../components/library/BookList";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Helmet } from "react-helmet-async";

const PublisherPage = () => {
  const { publisherId } = useParams();
  const [books, setBooks] = useState([]);
  const [publisher, setPublisher] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPublisherAndBooks = async () => {
      setLoading(true);
      try {
        const [booksData, publisherData] = await Promise.all([
          getBooksById(publisherId),
          getPublisherById(publisherId),
        ]);
        setBooks(booksData);
        setPublisher(publisherData);
      } catch (error) {
        console.error("Error fetching publisher and books:", error);
        setError("Failed to load publisher data");
      } finally {
        setLoading(false);
      }
    };
    fetchPublisherAndBooks();
  }, [publisherId]);

  return (
    <div>
      <Helmet>
        <title>{publisher ? `${publisher.name} | Maidan` : "Loading..."}</title>
      </Helmet>
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <div>{error}</div>
      ) : !publisher ? (
        <div>Publisher not found</div>
      ) : (
        <>
          <h3>{publisher.name}</h3>
          <h3 style={{ marginLeft: 8 }}>Description</h3>
          <div style={{ marginLeft: 8 }}>{publisher.description}</div>
          <h3 style={{ marginLeft: 8 }}>Books</h3>
          <BookList books={books} />
        </>
      )}
    </div>
  );
};

export default PublisherPage;
