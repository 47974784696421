import React, { useState, useEffect } from "react";
import { Button } from "antd";
import {
  LeftOutlined,
  RightOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { getGuardAttendanceReportByDate } from "../../api/GuardAPI";
import { formatTime, formatDate } from "../../helpers/dateTimeHelper";
import "../../css/guards/GuardAttendanceDailyReport.css";
import LoadingSpinner from "../../components/LoadingSpinner";

const GuardAttendanceDailyReport = () => {
  const [attendanceData, setAttendanceData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(formatDate(new Date()));
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const hours = Array.from(
    { length: 24 },
    (_, i) => `${i.toString().padStart(2, "0")}:00`
  );

  useEffect(() => {
    const fetchReport = async () => {
      setLoading(true);
      try {
        const data = await getGuardAttendanceReportByDate(selectedDate);
        setAttendanceData(data);
      } catch (error) {
        console.error("Error fetching attendance report:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchReport();
  }, [selectedDate]);

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);
  };

  const handlePreviousDate = () => {
    const previousDate = new Date(selectedDate);
    previousDate.setDate(previousDate.getDate() - 1);
    setSelectedDate(formatDate(previousDate));
  };

  const handleNextDate = () => {
    const nextDate = new Date(selectedDate);
    nextDate.setDate(nextDate.getDate() + 1);
    setSelectedDate(formatDate(nextDate));
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseViewer = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      <h2>Report for {formatDate(selectedDate, "DD-MM-YY")}</h2>
      <div className="report-controls">
        <Button
          icon={<LeftOutlined />}
          onClick={handlePreviousDate}
          style={{ marginRight: 10 }}
        />
        <input type="date" value={selectedDate} onChange={handleDateChange} />
        <Button
          icon={<RightOutlined />}
          onClick={handleNextDate}
          style={{ marginLeft: 10 }}
        />
      </div>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="table-container">
            <table className="attendance-table">
              <thead>
                <tr>
                  <th>Hour</th>
                  {attendanceData.map((guard) => (
                    <th key={guard.guard_id}>{guard.guard_name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Summary</td>
                  {attendanceData.map((guard) => (
                    <td key={guard.guard_id}>
                      Present: {guard.present_count} ({guard.present_percent}%)
                      <br />
                      Late: {guard.late_count} ({guard.late_percent}%)
                    </td>
                  ))}
                </tr>
                {hours.map((hour) => (
                  <tr key={hour}>
                    <td>{hour}</td>
                    {attendanceData.map((guard) => {
                      const status = guard.hourly_statuses.find(
                        (h) => h.hour === hour
                      );
                      const cellClass = status
                        ? status.status === "absent" || status.status === "late"
                          ? "absent-late"
                          : ""
                        : "no-data";
                      return (
                        <td key={guard.guard_id} className={cellClass}>
                          {status ? (
                            <>
                              {status.selfie_url ? (
                                <div>
                                  <img
                                    src={status.selfie_url}
                                    alt="selfie"
                                    className="selfie-image"
                                    onClick={() =>
                                      handleImageClick(status.selfie_url)
                                    }
                                  />
                                  <div>
                                    {status.latitude && status.longitude && (
                                      <a
                                        href={`https://www.google.com/maps?q=${status.latitude},${status.longitude}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <EnvironmentOutlined />
                                      </a>
                                    )}
                                    {formatTime(status.time)}
                                  </div>
                                </div>
                              ) : (
                                <span>{status.status}</span>
                              )}
                            </>
                          ) : (
                            <span></span>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {selectedImage && (
            <div className="image-viewer-overlay" onClick={handleCloseViewer}>
              <div className="image-viewer-content">
                <img src={selectedImage} alt="Large view" />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default GuardAttendanceDailyReport;
