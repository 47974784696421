import React, { useEffect, useRef } from "react";
import { Form, Input, Button, Typography } from "antd";
import { formFocusHelper } from "../../helpers/formFocusHelper";

const { Title } = Typography;

const PublisherEditForm = ({
  publisher = {},
  onFinish,
  refreshPublishers,
  title,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    return () => formFocusHelper(ref); 
  }, []);

  const handleFinish = (publisherData) => {
    onFinish(publisherData)
      .then(() => {
        refreshPublishers();
      })
      .catch((error) => {
        console.error("Error handling publisher:", error);
      });
  };

  return (
    <>
      <Title level={2}>{title}</Title>
      <Form
        initialValues={publisher}
        onFinish={handleFinish}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please input the name." }]}
        >
          <Input ref={ref}/>
        </Form.Item>

        <Form.Item name="address" label="Address">
          <Input />
        </Form.Item>

        <Form.Item name="contact_info" label="Contact Information">
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {publisher.id ? "Update Publisher" : "Create Publisher"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default PublisherEditForm;
