// MasteryLevelIndicator.js
import React from "react";
import "../css/record-keeper/MasteryLevelIndicator.css";

const masteryLevelIcons = {
  2: "/icons/mastery-level-2.png",
  3: "/icons/mastery-level-3.png",
  4: "/icons/mastery-level-4.png",
  5: "/icons/mastery-level-5.png",
};

export const MasteryLevelIndicator = ({ level, levelName, cover_image_url}) => {
  if (level === 1) return null;

  return (
    <div className="mastery-level" title={levelName}>
      <img
        src={masteryLevelIcons[level] || cover_image_url}
        alt={levelName || `Level ${level}`}
        className="mastery-icon"
      />
    </div>
  );
};

export const MasteryLevelLegend = ({ masteryLevels }) => (
  <div className="mastery-level-legend">
    <div className="legend-items">
      {masteryLevels
        .filter((level) => level.level !== 1)
        .map(({ level, name, cover_image_url }) => (
          <div key={`legend-${level}`} className="legend-item">
            <MasteryLevelIndicator level={level} levelName={name} cover_image_url={cover_image_url}/>
            <span>{name}</span>
          </div>
        ))}
    </div>
  </div>
);
