import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Drawer, Button, Input } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import ClassroomEditForm from "./ClassroomEditForm";
import ClassroomCardList from "./ClassroomCardList";
import { useAbility } from "../hooks/useAbility";
import { createClassroom, updateClassroom } from "../api/SchoolAPI";
import { createResponsiveFilterLayout } from "../helpers/responsiveFilterLayoutHelper";
import useIsMobile from "../hooks/useIsMobile";
import requestHandler from "../utils/requestHandler";
import { getFullName } from "../helpers/childHelper";
import CustomTable from "./CustomTable";

const { Search } = Input;

const NewButton = ({ onClick, ability }) => (
  <Button
    type="default"
    onClick={onClick}
    icon={<PlusOutlined />}
    disabled={!ability.can("create", "classroom")}
  />
);

const SearchBar = ({ value, onChange }) => (
  <Search
    placeholder="Search by name"
    allowClear
    value={value}
    onChange={onChange}
  />
);

const ClassroomList = ({
  classrooms,
  refreshClassrooms,
  showNewButton = false,
  showSearchBar = false,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedClassroom, setSelectedClassroom] = useState(null);

  const ability = useAbility();
  const isMobile = useIsMobile();

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredClassrooms = classrooms.filter((classroom) =>
    classroom.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleEditClassroom = (record) => {
    if (ability.can("update", "classroom")) {
      setSelectedClassroom(record);
      setIsDrawerOpen(true);
    }
  };

  const handleNewClassroom = () => {
    setSelectedClassroom({});
    setIsDrawerOpen(true);
  };

  const handleFormFinish = async (classroomData) => {
    if (selectedClassroom && selectedClassroom.id) {
      await requestHandler.execute(
        updateClassroom(selectedClassroom.id, classroomData),
        "Classroom updated",
        "Failed to update classroom"
      );
    } else {
      await requestHandler.execute(
        createClassroom(classroomData),
        "New classroom created",
        "Failed to create classroom"
      );
    }
    setIsDrawerOpen(false);
    refreshClassrooms();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/classrooms/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "Child Count",
      dataIndex: "child_count",
      key: "child_count",
    },
    {
      title: "Teachers",
      dataIndex: "teachers",
      key: "teachers",
      render: (teachers) =>
        teachers && teachers?.length > 0
          ? teachers.map((teacher) => getFullName(teacher)).join(", ")
          : "No teachers",
    },
    {
      title: "Grades",
      dataIndex: "grades",
      key: "grades",
      render: (grades) =>
        grades && grades.length > 0
          ? grades.map((grade) => grade.name).join(", ")
          : "No grades",
    },
    {
      key: "action",
      render: (_, record) => (
        <Button
          icon={<EditOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            handleEditClassroom(record);
          }}
          disabled={!ability.can("update", "classroom")}
        />
      ),
      width: 50,
    },
  ];

  const filterComponents = [
    {
      component: <NewButton onClick={handleNewClassroom} ability={ability} />,
      show: showNewButton && ability.can("create", "classroom"),
      desktopSpan: 1,
      mobileSpan: 3,
    },
    {
      component: <SearchBar value={searchQuery} onChange={handleSearch} />,
      show: showSearchBar,
      desktopSpan: 8,
      mobileSpan: 21,
    },
  ];

  const { desktopLayout, mobileLayout } =
    createResponsiveFilterLayout(filterComponents);

  return (
    <div>
      {isMobile ? mobileLayout : desktopLayout}
      {isMobile ? (
        <ClassroomCardList
          classrooms={filteredClassrooms}
          onClassroomClick={handleEditClassroom}
          ability={ability}
        />
      ) : (
        <CustomTable columns={columns} dataSource={filteredClassrooms} />
      )}
      <Drawer
        width={350}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedClassroom(null);
        }}
        open={isDrawerOpen}
        closable={false}
      >
        {isDrawerOpen && (
          <ClassroomEditForm
            classroom={selectedClassroom}
            onFinish={handleFormFinish}
            refreshClassrooms={refreshClassrooms}
            title={
              selectedClassroom && selectedClassroom.id
                ? "Edit Classroom"
                : "New Classroom"
            }
          />
        )}
      </Drawer>
    </div>
  );
};

export default ClassroomList;
