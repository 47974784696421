// helpers/setupHelper.js
import { Link } from "react-router-dom";

export const getIncompleteSetupMessage = (currentUser) => {
  if (currentUser.role === "Teacher" && currentUser.classrooms.length === 0) {
    return (
      <>
        <div>No classroom has been assigned to you.</div>
        <div>Please contact your school admin.</div>
      </>
    );
  }

  if (currentUser.onboardingStatus && !currentUser.onboardingStatus.status) {
    if (currentUser.role === "Teacher") {
      return (
        <>
          <div>Your school's onboarding is incomplete.</div>
          <div>Please contact your school admin.</div>
        </>
      );
    }

    if (!currentUser.onboardingStatus.checklist.add_children.completed) {
      return (
        <>
          <div>Your school has no children.</div>
          <div>
            <Link to="/children">Add Children</Link>
          </div>
        </>
      );
    }

    if (!currentUser.onboardingStatus.checklist.add_classrooms.completed) {
      return (
        <>
          <div>Your school has no classrooms.</div>
          <div>
            <Link to="/classrooms">Add Classrooms</Link>
          </div>
        </>
      );
    }

    if (
      !currentUser.onboardingStatus.checklist.assign_children_to_classrooms
        .completed
    ) {
      return (
        <>
          <div>Your classroom has no children.</div>
          <div>
            <Link to="/classrooms">Assign Children To Classrooms</Link>
          </div>
        </>
      );
    }
  }

  return null;
};
