import React, { useState, useEffect } from "react";
import { Modal, Table, Input } from "antd";
import { getBooksNotByCollection } from "../../api/library/CollectionAPI"; 

const { Search } = Input;

const ManageBookCollectionModal = ({
  collectionId,
  isVisible,
  onClose,
  onAdd,
  mode,
  existingBooks,
}) => {
  const [books, setBooks] = useState([]);
  const [selectedBooks, setSelectedBooks] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (isVisible) {
      const fetchBooks = async () => {
        try {
          let books = [];
          if (mode === "add") {
            books = await getBooksNotByCollection(collectionId);
          } else {
            books = existingBooks;
          }
          setBooks(books);
        } catch (error) {
          console.error("Error fetching books:", error);
        }
      };
      fetchBooks();
    }
  }, [isVisible, collectionId, mode, existingBooks]);

  const handleAddBooks = () => {
    onAdd(selectedBooks);
    setSelectedBooks([]);
  };

  const handleBookSelection = (selectedRowKeys) => {
    setSelectedBooks(selectedRowKeys);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredBooks = books.filter((book) =>
    book.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const bookColumns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Book ID",
      dataIndex: "id",
      key: "id",
    }
  ];

  const rowSelection = {
    selectedRowKeys: selectedBooks,
    onChange: handleBookSelection,
  };

  return (
    <Modal
      title={
        mode === "add"
          ? "Add Books to Collection"
          : "Remove Books from Collection"
      }
      open={isVisible}
      onOk={handleAddBooks}
      onCancel={onClose}
      okText={mode === "add" ? "Add to Collection" : "Remove from Collection"}
    >
      <Search
        placeholder="Search by title"
        allowClear
        onChange={handleSearch}
        style={{ marginBottom: 16 }}
      />
      <Table
        rowSelection={rowSelection}
        columns={bookColumns}
        dataSource={filteredBooks}
        rowKey="id"
        pagination={{ pageSize: 10 }}
      />
    </Modal>
  );
};

export default ManageBookCollectionModal;
