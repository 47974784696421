import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import ChildList from "../components/ChildList";
import LoadingSpinner from "../components/LoadingSpinner";
import { getChildren } from "../api/ChildAPI";

const ChildrenPage = () => {
  const [children, setChildren] = useState([]);
  const [loading, setLoading] = useState(true);

  const refreshChildren = async () => {
    setLoading(true);
    try {
      const data = await getChildren();
      setChildren(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshChildren();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Children | Maidan</title>
      </Helmet>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <h1 className="page-heading">Children</h1>
          <ChildList
            children={children}
            refreshChildren={refreshChildren}
            showNewButton={true}
            showSortOption={true}
            showSearchBar={true}
          />
        </div>
      )}
    </div>
  );
};

export default ChildrenPage;
