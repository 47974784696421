import React, { useState, useEffect } from "react";
import { Modal, Table, Input } from "antd";
import { getCollectionsNotByBook } from "../../api/library/BookAPI"; 

const { Search } = Input;

const ManageCollectionBookModal = ({
  bookId,
  isVisible,
  onClose,
  onAdd,
  mode,
  existingCollections,
}) => {
  const [collections, setCollections] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (isVisible) {
      const fetchCollections = async () => {
        try {
          let collections = [];
          if (mode === "add") {
            collections = await getCollectionsNotByBook(bookId);  // Fetch collections not mapped to book
          } else {
            collections = existingCollections;  // Use existing collections mapped to the book
          }
          setCollections(collections);
        } catch (error) {
          console.error("Error fetching collections:", error);
        }
      };
      fetchCollections();
    }
  }, [isVisible, bookId, mode, existingCollections]);

  const handleAddCollections = () => {
    onAdd(selectedCollections);  // Trigger the function to handle collection-book mapping
    setSelectedCollections([]);
  };

  const handleCollectionSelection = (selectedRowKeys) => {
    setSelectedCollections(selectedRowKeys);  // Update selected collections
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);  // Set the search query for filtering collections
  };

  const filteredCollections = collections.filter((collection) =>
  collection.name.toLowerCase().includes(searchQuery.toLowerCase())  // Filter collections by name
  );

  const collectionColumns = [
    {
        title: "Collection ID",
        dataIndex: "id",
        key: "id",
    },
    {
      title: "Collection Name",
      dataIndex: "name",
      key: "name",
    }
  ];

  const rowSelection = {
    selectedRowKeys: selectedCollections,
    onChange: handleCollectionSelection,
  };

  return (
    <Modal
      title={
        mode === "add"
          ? "Add Collections to Book"
          : "Remove Collections from Book"
      }
      open={isVisible}
      onOk={handleAddCollections}
      onCancel={onClose}
      okText={mode === "add" ? "Add to Book" : "Remove from Book"}
    >
      <Search
        placeholder="Search by collection name"
        allowClear
        onChange={handleSearch}
        style={{ marginBottom: 16 }}
      />
      <Table
        rowSelection={rowSelection}
        columns={collectionColumns}
        dataSource={filteredCollections}
        rowKey="id"
        pagination={{ pageSize: 10 }}
      />
    </Modal>
  );
};

export default ManageCollectionBookModal;
