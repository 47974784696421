import { Table, Card } from "antd";
import { getMasteryLevelsByChild } from "../api/RecordKeeperAPI";
import { getAttendanceByChild } from "../api/AttendanceAPI";
import AttendanceTable from "./AttendanceTable";
import {
  MasteryLevelIndicator,
  MasteryLevelLegend,
} from "./MasteryLevelIndicator";

export const renderMasteryTable = (
  subjectData,
  schoolMasteryLevels,
  userRole
) => {
  const filteredData = subjectData.filter((item) => item.level !== 1);

  return (
    <>
      <MasteryLevelLegend masteryLevels={schoolMasteryLevels} />
      <Table
        columns={[
          {
            title: "Lesson",
            dataIndex: "lesson_name",
            key: "lesson_name",
            width: 400,
          },
          {
            title: "Level",
            dataIndex: "level",
            key: "level",
            width: 50,
            render: (level) => (
              <MasteryLevelIndicator
                level={level}
                levelName={
                  schoolMasteryLevels.find((ml) => ml.level === level)?.name
                }
                cover_image_url={
                  schoolMasteryLevels.find((ml) => ml.level === level)?.cover_image_url
                }
              />
            ),
          },
          ...(userRole !== "Guardian"
            ? [
                {
                  title: "Notes",
                  dataIndex: "notes",
                  key: "notes",
                  render: (notes) => (
                    <div>
                      {notes?.map((note) => (
                        <div key={`note-${note.id}`}>• {note.note}</div>
                      ))}
                    </div>
                  ),
                },
              ]
            : []),
        ]}
        dataSource={filteredData.map((item) => ({
          key: `lesson-${item.lesson_id}`,
          lesson_name: item.lesson_name,
          level: item.level,
          ...(userRole !== "Guardian" && { notes: item.notes }),
        }))}
        pagination={false}
        className="development-record-table"
      />
    </>
  );
};

export const renderMasteryCards = (
  subjectData,
  schoolMasteryLevels,
  userRole
) => {
  const filteredData = subjectData.filter((item) => item.level !== 1);

  return (
    <div className="mastery-card-list">
      <MasteryLevelLegend masteryLevels={schoolMasteryLevels} />
      {filteredData.map((item) => (
        <Card
          key={`card-${item.lesson_id}`}
          className="mastery-card"
          style={{ marginBottom: 8 }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "10px", justifyContent: "space-around" }}>
            <p>{item.lesson_name}</p>
            <MasteryLevelIndicator
              level={item.level}
              levelName={
                schoolMasteryLevels.find((ml) => ml.level === item.level)?.name
              }
              cover_image_url={
                schoolMasteryLevels.find((ml) => ml.level === item.level)?.cover_image_url
              }
            />
          </div>
          {userRole !== "Guardian" && item.notes?.length > 0 && (
            <ul>
              {item.notes.map((note) => (
                <li key={`note-${note.id}`}>{note.note}</li>
              ))}
            </ul>
          )}
        </Card>
      ))}
    </div>
  );
};

export const renderMasteryContent = (
  masteryLevels,
  selectedSubject,
  isMobile,
  print,
  userRole,
  schoolMasteryLevels
) => {
  const renderSubjectContent = (subjectId, subjectData) => (
    <div key={subjectId} style={{ marginBottom: 24 }}>
      <h2>{subjectData[0].subject_name}</h2>
      {isMobile && !print
        ? renderMasteryCards(subjectData, schoolMasteryLevels, userRole)
        : renderMasteryTable(subjectData, schoolMasteryLevels, userRole)}
    </div>
  );

  if (selectedSubject && masteryLevels[selectedSubject]) {
    return renderSubjectContent(
      selectedSubject,
      masteryLevels[selectedSubject]
    );
  }

  return Object.entries(masteryLevels).map(([subjectId, subjectData]) =>
    renderSubjectContent(subjectId, subjectData)
  );
};

export const renderAttendanceContent = (attendance, print, userRole) => {
  if (attendance) {
    return (
      <AttendanceTable
        attendanceData={attendance}
        userRole={userRole}
        print={print}
      />
    );
  }
};

export const fetchMasteryLevels = async (childId, setData) => {
  try {
    if (!childId) return;

    const [{ masteryLevels, childMasteryData }, attendanceData] =
      await Promise.all([
        getMasteryLevelsByChild(childId),
        getAttendanceByChild(childId),
      ]);

    const subjectMasteryLevels = {};
    const subjects = [];

    childMasteryData.forEach((item) => {
      if (!subjectMasteryLevels[item.subject_id]) {
        subjectMasteryLevels[item.subject_id] = [];
        subjects.push({
          id: item.subject_id,
          name: item.subject_name,
        });
      }
      subjectMasteryLevels[item.subject_id].push(item);
    });

    setData((prevData) => ({
      ...prevData,
      masteryLevels: subjectMasteryLevels,
      subjects,
      attendance: attendanceData,
      schoolMasteryLevels: masteryLevels,
    }));
  } catch (error) {
    console.error("Error fetching mastery levels and attendance:", error);
    throw error;
  }
};
