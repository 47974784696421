import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Drawer, Button, Input } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import GradeEditForm from "./GradeEditForm";
import { useAbility } from "../hooks/useAbility";
import { createGrade, updateGrade } from "../api/GradeAPI";
import { createResponsiveFilterLayout } from "../helpers/responsiveFilterLayoutHelper";
import useIsMobile from "../hooks/useIsMobile";
import requestHandler from "../utils/requestHandler";
import CustomTable from "./CustomTable";

const { Search } = Input;

const NewButton = ({ onClick, ability }) => (
  <Button
    type="default"
    onClick={onClick}
    icon={<PlusOutlined />}
    disabled={!ability.can("create", "grade")}
  />
);

const SearchBar = ({ value, onChange }) => (
  <Search
    placeholder="Search by name"
    allowClear
    value={value}
    onChange={onChange}
  />
);

const GradeList = ({
  grades,
  refreshGrades,
  showNewButton = false,
  showSearchBar = false,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState(null);

  const ability = useAbility();
  const isMobile = useIsMobile();

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredGrades = grades.filter((grade) =>
    grade.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleEditGrade = (record) => {
    if (ability.can("update", "grade")) {
      setSelectedGrade(record);
      setIsDrawerOpen(true);
    }
  };

  const handleNewGrade = () => {
    setSelectedGrade({});
    setIsDrawerOpen(true);
  };

  const handleFormFinish = async (gradeData) => {
    if (selectedGrade && selectedGrade.id) {
      await requestHandler.execute(
        updateGrade(selectedGrade.id, gradeData),
        "Grade updated.",
        "Failed to update grade."
      );
    } else {
      await requestHandler.execute(
        createGrade(gradeData),
        "New grade created.",
        "Failed to create grade."
      );
    }
    setIsDrawerOpen(false);
    refreshGrades();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => <Link to={`/grades/${record.id}`}>{text}</Link>,
      width: 200,
    },
    {
      title: "Student Count",
      dataIndex: "student_count",
      key: "student_count",
      render: (count) => (count !== undefined ? count : "N/A"),
    },
    {
      key: "action",
      render: (_, record) => (
        <Button
          icon={<EditOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            handleEditGrade(record);
          }}
          disabled={!ability.can("update", "grade")}
        />
      ),
      width: 50,
    },
  ];

  const filterComponents = [
    {
      component: <NewButton onClick={handleNewGrade} ability={ability} />,
      show: showNewButton && ability.can("create", "grade"),
      desktopSpan: 1,
      mobileSpan: 3,
    },
    {
      component: <SearchBar value={searchQuery} onChange={handleSearch} />,
      show: showSearchBar,
      desktopSpan: 23,
      mobileSpan: 21,
    },
  ];

  const { desktopLayout, mobileLayout } =
    createResponsiveFilterLayout(filterComponents);

  return (
    <div>
      {isMobile ? mobileLayout : desktopLayout}
      <CustomTable columns={columns} dataSource={filteredGrades} />
      <Drawer
        width={350}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedGrade(null);
        }}
        open={isDrawerOpen}
        closable={false}
      >
        {isDrawerOpen && (
          <GradeEditForm
            grade={selectedGrade}
            onFinish={handleFormFinish}
            refreshGrades={refreshGrades}
            title={
              selectedGrade && selectedGrade.id ? "Edit Grade" : "New Grade"
            }
          />
        )}
      </Drawer>
    </div>
  );
};

export default GradeList;
