import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Select } from "antd";
import LoadingSpinner from "../../components/LoadingSpinner";
import MembershipInfo from "../../components/MembershipInfo";
import { useAuth } from "../../contexts/AuthContext";
import {
  renderMasteryContent,
  fetchMasteryLevels,
  renderAttendanceContent,
} from "../../helpers/developmentRecordHelper";
import "../../css/record-keeper/RecordKeeperPage.css";

const { Option } = Select;

const GuardianViewDevelopmentRecordPage = ({ isMobile }) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState({
    subjects: [],
    masteryLevels: {},
    children: [],
    attendance: [],
    schoolMasteryLevels: [],
  });
  const [filters, setFilters] = useState({
    selectedChild: "",
    selectedSubject: "",
  });

  useEffect(() => {
    if (currentUser.children && currentUser.children.length > 0) {
      const transformedChildren = currentUser.children.map((child) => ({
        id: child.child_id,
        sid: child.sid,
        first_name: child.first_name,
        last_name: child.last_name,
        date_of_birth: child.date_of_birth,
        blood_group: child.blood_group,
        gender: child.gender,
        date_of_joining: child.date_of_joining,
        current_address: child.current_address,
        profile_photo_url: child.profile_photo_url || "",
        classrooms: child.classrooms,
        grade: child.grade,
        school_id: child.school_id,
      }));

      setData((prevData) => ({
        ...prevData,
        children: transformedChildren,
      }));

      setFilters((prevFilters) => ({
        ...prevFilters,
        selectedChild: transformedChildren[0].id,
      }));
    }
    setLoading(false);
  }, [currentUser.children]);

  useEffect(() => {
    if (filters.selectedChild) {
      const selectedChild = data.children.find(
        (child) => child.id === filters.selectedChild
      );
      if (selectedChild) {
        fetchMasteryLevels(filters.selectedChild, setData);
      }
    }
  }, [filters.selectedChild, data.children]);

  const handleChange = (field) => (value) => {
    setFilters((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const selectedChildData = data.children.find(
    (child) => child.id === filters.selectedChild
  );

  return (
    <div className="development-record-page">
      <Helmet>
        <title>Development Record | Maidan</title>
      </Helmet>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {data.children.length === 2 && (
            <Select
              style={{ width: 200, marginBottom: 16, marginRight: 16 }}
              value={filters.selectedChild}
              onChange={handleChange("selectedChild")}
              placeholder="Select Child"
            >
              {data.children.map((child) => (
                <Option key={child.id} value={child.id}>
                  {child.first_name} {child.last_name}
                </Option>
              ))}
            </Select>
          )}

          {selectedChildData && (
            <MembershipInfo
              membership={selectedChildData}
              id={selectedChildData.sid}
              classrooms={selectedChildData.classrooms}
            />
          )}

          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

          <div>
            {renderMasteryContent(
              data.masteryLevels,
              filters.selectedSubject,
              isMobile,
              null,
              "Guardian",
              data.schoolMasteryLevels
            )}

            {data.attendance.length > 0 && (
              <>
                <h2>Attendance</h2>
                <div>
                  {renderAttendanceContent(data.attendance, null, "Guardian")}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default GuardianViewDevelopmentRecordPage;
