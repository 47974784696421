import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Drawer, Button, Input, Dropdown, Modal } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import SubjectEditForm from "./SubjectEditForm";
import ImportLessons from "./ImportLessons";
import { createSubject, updateSubject } from "../api/SubjectAPI";
import { useAbility } from "../hooks/useAbility";
import { createResponsiveFilterLayout } from "../helpers/responsiveFilterLayoutHelper";
import useIsMobile from "../hooks/useIsMobile";
import requestHandler from "../utils/requestHandler";
import CustomTable from "./CustomTable";

const { Search } = Input;

const NewButton = ({ onClick, onImport, ability }) => {
  
  const menuItems = [
    {
      key: "new-subject",
      label: "New Subject",
      onClick: onClick,
    },
    {
      key: "import-lessons",
      label: "Import",
      onClick: onImport,
    },
  ];

  return (
    <Dropdown menu={{ items: menuItems }} trigger={["click"]}>
      <Button type="default" icon={<PlusOutlined />} disabled={!ability.can("create", "subject")} />
    </Dropdown>  
  )
}

const SearchBar = ({ value, onChange }) => (
  <Search
    placeholder="Search by name"
    allowClear
    value={value}
    onChange={onChange}
  />
);

const SubjectList = ({
  subjects = [],
  refreshSubjects,
  showNewButton = false,
  showSearchBar = false,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const ability = useAbility();
  const isMobile = useIsMobile();

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredSubjects = Array.isArray(subjects)
    ? subjects.filter((subject) =>
        subject.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  const handleEditSubject = (record) => {
    if (ability.can("update", "subject")) {
      setSelectedSubject(record);
      setIsDrawerOpen(true);
    }
  };

  const handleNewSubject = () => {
    setSelectedSubject({});
    setIsDrawerOpen(true);
  };

  const handleFormFinish = async (subjectData) => {
    if (selectedSubject && selectedSubject.id) {
      await requestHandler.execute(
        updateSubject(selectedSubject.id, subjectData),
        "Subject updated.",
        "Failed to update subject."
      );
    } else {
      await requestHandler.execute(
        createSubject(subjectData),
        "New subject created.",
        "Failed to create subject."
      );
    }
    setIsDrawerOpen(false);
    refreshSubjects();
  };

  const columns = [
    {
      title: "Subject Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/lesson-editor/subjects/${record.id}`}>{text}</Link>
      ),
    },
    {
      key: "action",
      render: (_, record) => (
        <Button
          icon={<EditOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            handleEditSubject(record);
          }}
          disabled={!ability.can("update", "subject")}
        />
      ),
      width: 50,
    },
  ];

  const filterComponents = [
    {
      component: (
        <NewButton
          onClick={handleNewSubject}
          onImport={() => setIsModalOpen(true)}
          ability={ability}
        />
      ),
      show: showNewButton && ability.can("create", "subject"),
      desktopSpan: 1,
      mobileSpan: 3,
    },
    {
      component: <SearchBar value={searchQuery} onChange={handleSearch} />,
      show: showSearchBar,
      desktopSpan: 23,
      mobileSpan: 21,
    },
  ];

  const { desktopLayout, mobileLayout } =
    createResponsiveFilterLayout(filterComponents);

  return (
    <div>
      {isMobile ? mobileLayout : desktopLayout}
      <CustomTable columns={columns} dataSource={filteredSubjects} />
      {ability.can("update", "subject") && (
        <Drawer
          width={350}
          onClose={() => {
            setIsDrawerOpen(false);
            setSelectedSubject(null);
          }}
          open={isDrawerOpen}
          closable={false}
        >
          {isDrawerOpen && (
            <SubjectEditForm
              subject={selectedSubject}
              onFinish={handleFormFinish}
              refreshSubjects={refreshSubjects}
              title={
                selectedSubject && selectedSubject.id
                  ? "Edit Subject"
                  : "New Subject"
              }
            />
          )}
        </Drawer>
      )}

      <Modal
        title="Import Lessons"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <ImportLessons />
      </Modal>
    </div>
  );
};

export default SubjectList;
