import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import { Helmet } from "react-helmet-async";
import BookList from "../../components/library/BookList";
import ManageBookCategoryModal from "../../components/library/ManageBookCategoryModal";
import LoadingSpinner from "../../components/LoadingSpinner";
import {
  getCategoryById,
  getBooksByCategory,
  mapBooksToCategory,
  unmapBooksToCategory,
} from "../../api/library/CategoryAPI";

const CategoryPage = () => {
  const { categoryId } = useParams();
  const [category, setCategory] = useState(null);
  const [books, setBooks] = useState([]);
  const [isBooksModalVisible, setIsBooksModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCategoryAndBooks = useCallback(async () => {
    setLoading(true);
    try {
      const [categoryData, booksData] = await Promise.all([
        getCategoryById(categoryId),
        getBooksByCategory(categoryId),
      ]);
      setCategory(categoryData);
      setBooks(booksData);
    } catch (error) {
      console.error("Error fetching category and books:", error);
      setError("Failed to load category data");
    } finally {
      setLoading(false);
    }
  }, [categoryId]);

  useEffect(() => {
    fetchCategoryAndBooks();
  }, [fetchCategoryAndBooks]);

  const handleAddBooks = async (bookIds) => {
    try {
      await Promise.all(
        bookIds.map((bookId) => mapBooksToCategory(bookId, categoryId))
      );
      setIsBooksModalVisible(false);
      fetchCategoryAndBooks();
    } catch (error) {
      console.error("Error adding books to category:", error);
      setError("Failed to add books to category");
    }
  };

  const handleRemoveBooks = async (bookIds) => {
    try {
      await Promise.all(
        bookIds.map((bookId) => unmapBooksToCategory(bookId, categoryId))
      );
      setIsBooksModalVisible(false);
      fetchCategoryAndBooks();
    } catch (error) {
      console.error("Error removing books from category:", error);
      setError("Failed to remove books from category");
    }
  };

  return (
    <div>
      <Helmet>
        <title>{category ? `${category.name} | Maidan` : "Loading..."}</title>
      </Helmet>
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <div>{error}</div>
      ) : !category ? (
        <div>Category not found</div>
      ) : (
        <>
          <h2>{category.name}</h2>
          <h3 style={{ marginLeft: 8 }}>Description</h3>
          <div style={{ marginLeft: 8 }}>{category.description}</div>
          <h3 style={{ marginLeft: 8 }}>Books</h3>
          <Button
            onClick={() => {
              setModalMode("add");
              setIsBooksModalVisible(true);
            }}
            style={{ marginLeft: 8 }}
          >
            Add Books
          </Button>
          <Button
            onClick={() => {
              setModalMode("remove");
              setIsBooksModalVisible(true);
            }}
            style={{ marginLeft: 8 }}
          >
            Remove Books
          </Button>
          <BookList books={books} />
          <ManageBookCategoryModal
            categoryId={categoryId}
            isVisible={isBooksModalVisible}
            onClose={() => setIsBooksModalVisible(false)}
            onAdd={modalMode === "add" ? handleAddBooks : handleRemoveBooks}
            mode={modalMode}
            existingBooks={books}
          />
        </>
      )}
    </div>
  );
};

export default CategoryPage;
