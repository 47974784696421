import React, { useState, useEffect } from "react";
import { getGuardAttendanceReportByMonth } from "../../api/GuardAPI";
import "../../css/guards/GuardAttendanceMonthlyReport.css";
import { formatDate } from "../../helpers/dateTimeHelper";

const GuardAttendanceMonthlyReport = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(
    formatDate(new Date(), "YYYY-MM")
  );
  const [daysInMonth, setDaysInMonth] = useState([]);

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const data = await getGuardAttendanceReportByMonth(selectedMonth);
        setAttendanceData(data);
      } catch (error) {
        console.error("Error fetching monthly attendance report:", error);
      }
    };
    fetchReport();
  }, [selectedMonth]);

  useEffect(() => {
    const [year, month] = selectedMonth.split("-");
    const days = new Date(year, month, 0).getDate(); // Get number of days in the month
    setDaysInMonth(Array.from({ length: days }, (_, i) => i + 1));
  }, [selectedMonth]);

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value); // Native input provides YYYY-MM format
  };

  return (
    <div className="attendance-monthly-report-container">
      <h2>Report for {selectedMonth}</h2>
      <div className="report-controls">
        <input
          type="month"
          value={selectedMonth}
          onChange={handleMonthChange}
        />
      </div>
      <div className="table-container">
        <table className="attendance-table">
          <thead>
            <tr>
              <th>Day</th>
              {attendanceData.map((guard) => (
                <th key={guard.guard_id}>{guard.guard_name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* Summary Row */}
            <tr>
              <td>Summary</td>
              {attendanceData.map((guard) => (
                <td key={guard.guard_id}>
                  Present: {guard.present_percent}%
                  <br />
                  Late: {guard.late_percent}%
                </td>
              ))}
            </tr>
            {/* Daily Attendance Rows */}
            {daysInMonth.map((day) => (
              <tr key={day}>
                <td>{day}</td>
                {attendanceData.map((guard) => {
                  const dailyAttendance = guard.daily_attendance.find(
                    (attendance) => new Date(attendance.day).getDate() === day
                  );
                  return (
                    <td key={guard.guard_id}>
                      Present: {dailyAttendance?.present_percent || 0}%
                      <br />
                      Late: {dailyAttendance?.late_percent || 0}%
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GuardAttendanceMonthlyReport;
