import React, { useState, useRef, useEffect, useCallback } from "react";
import { Modal, Upload, Button, message } from "antd";
import { UploadOutlined, CameraOutlined } from "@ant-design/icons";
import Cropper from "react-easy-crop";
import { updateMembershipProfilePhoto } from "../api/MembershipsAPI";
import requestHandler from "../utils/requestHandler";
import { validateFile, getCroppedImageBlob } from "../helpers/imageHelper";
import "../css/ProfilePhotoUploader.css";

const ProfilePhotoUploader = ({ memberId, initialPhotoUrl, refreshProfile }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState(
    initialPhotoUrl || "/icons/profile-icon.png"
  );
  const [isCaptureMode, setIsCaptureMode] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [imageCaptured, setImageCaptured] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const handleUploadClick = () => setIsModalOpen(true);

  const handleUpload = useCallback(async (file) => {
    setUploading(true);
    try {
      await requestHandler.execute(
        updateMembershipProfilePhoto(memberId, file),
        "Profile photo updated.",
        "Failed to upload profile photo."
      );
      setProfilePhotoUrl(URL.createObjectURL(file));
      refreshProfile?.();
      setIsModalOpen(false);
      setImageCaptured(false);
    } finally {
      setUploading(false);
    }
  },[memberId, refreshProfile])

  const beforeUpload = useCallback((file) => {
    const { isValid, message: errorMessage } = validateFile(file);
    if (!isValid) {
      message.error(errorMessage);
      setImageSrc(null);
      setImageCaptured(false);
      setVideoVisible(true);
      return Upload.LIST_IGNORE;
    }

    handleUpload(file);
    return false; // Prevent default upload behavior
  },[handleUpload])

  const handleCapturePhoto = () => {
    setIsCaptureMode(true);
    navigator.mediaDevices
      ?.getUserMedia({ video: true })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
          setVideoVisible(true);
  
          videoRef.current.onloadedmetadata = () => {
            const videoWidth = videoRef.current.videoWidth;
            const videoHeight = videoRef.current.videoHeight;
            canvasRef.current.width = videoWidth;
            canvasRef.current.height = videoHeight;
          };
        }
      })
      .catch(() => requestHandler.error(null, "Error accessing the camera"));
  };

  const handleCapture = () => {
    const videoWidth = videoRef.current.videoWidth;
    const videoHeight = videoRef.current.videoHeight;
    const context = canvasRef.current.getContext("2d"); 
    context.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);
    setImageCaptured(true);
    setVideoVisible(false);
    setImageSrc(canvasRef.current.toDataURL("image/png"));
  };

  const handleFileUpload = ({ file }) => {
    const reader = new FileReader();
    reader.onload = () => setImageSrc(reader.result);
    reader.readAsDataURL(file);
    setIsCaptureMode(false);
    stopVideoStream();
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const getCroppedImage = useCallback(async () => {
    try {
      const croppedBlob = await getCroppedImageBlob(imageSrc, croppedAreaPixels);
      beforeUpload(croppedBlob);
    } catch (error) {
      message.error("Failed to crop image");
    }
  }, [imageSrc, croppedAreaPixels, beforeUpload]);

  const stopVideoStream = () => {
    videoRef.current?.srcObject?.getTracks().forEach((track) => track.stop());
    setVideoVisible(false);
  };

  useEffect(() => () => stopVideoStream(), []);

  return (
    <>
      <img
        src={profilePhotoUrl}
        alt="Profile"
        className="profile-photo"
        onClick={handleUploadClick}
      />

      <Modal
        title="Update Profile Photo"
        open={isModalOpen}
        onCancel={() => {
          setIsCaptureMode(false);
          setImageSrc(null);
          setImageCaptured(false);
          setVideoVisible(true);
          setIsModalOpen(false);
          stopVideoStream();
        }}
        footer={null}
      >
        {!imageSrc ? (
          <div className="upload-options" style={{  margin:"24px 0px"}}>
            <Upload
              name="profilePhoto"
              customRequest={handleFileUpload}
              showUploadList={false}
            >
              <Button
                icon={<UploadOutlined />}
                loading={uploading}
                className="upload-button"
              >
                {uploading ? "Uploading..." : "Upload Photo"}
              </Button>
            </Upload>
            <Button
              icon={<CameraOutlined />}
              onClick={handleCapturePhoto}
              className="upload-button"
            >
              Capture Photo
            </Button>
          </div>
        ) : (
          <>
            <div className="crop-container">
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
            <div className="recapture-upload-buttons" style={{margin: "280px 0px 20px"}}>
              <Button
                onClick={() => {
                  setIsCaptureMode(false);
                  setImageSrc(null);
                  setImageCaptured(false);
                  setVideoVisible(true);
                  stopVideoStream();
                }}
                className="upload-button"
              >
                Recapture
              </Button>
              <Button
                type="primary"
                onClick={getCroppedImage}
                className="upload-button"
              >
                Upload Cropped Photo
              </Button>
            </div>
          </>
        )}
        {isCaptureMode && !imageCaptured && (
          <>
            <div className="video-canvas-container">
              <video
                ref={videoRef}
                className="video-canvas"
                style={{ display: videoVisible ? "block" : "none" }}
              />
              <canvas
                ref={canvasRef}
                className="video-canvas"
                style={{ display: imageCaptured ? "block" : "none" }}
                width="300"
                height="300"
              ></canvas>
            </div>
            <div className="recapture-upload-buttons">
              <Button
                icon={<CameraOutlined />}
                onClick={handleCapture}
                className="upload-button"
              >
                Capture
              </Button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default ProfilePhotoUploader;
