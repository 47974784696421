import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";
import { defineAbilitiesFor } from "../middleware/ability";

const AuthContext = createContext();

export { AuthContext };

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userAbility, setUserAbility] = useState(defineAbilitiesFor(null));
  const [loading, setLoading] = useState(true);

  const refreshUser = useCallback(async () => {
    try {
      const response = await axios.get("/auth/status");
      if (response.data.isLoggedIn && response.data.user) {
        setCurrentUser(response.data.user);
        setUserAbility(defineAbilitiesFor(response.data.user));
      } else {
        setCurrentUser(null);
        setUserAbility(defineAbilitiesFor(null));
      }
    } catch (error) {
      console.error("Failed to refresh user data", error);
      setUserAbility(defineAbilitiesFor(null));
    }
  }, []);

  useEffect(() => {
    refreshUser().then(() => setLoading(false));
  }, [refreshUser]);

  const handleLogout = () => {
    fetch("/auth/logout")
      .then((response) => {
        if (response.ok) {
          setCurrentUser(null);
          setUserAbility(defineAbilitiesFor(null));
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  const value = {
    currentUser,
    userAbility,
    setCurrentUser,
    loading,
    handleLogout,
    refreshUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
