import React from "react";
import { Helmet } from "react-helmet-async";
import { useAuth } from "../../contexts/AuthContext";
import TeacherPresentationsList from "../../components/record-keeper/TeacherPresentationsList";

const TeacherPresentationsPage = () => {
  const { currentUser } = useAuth();

  return (
    <>
      <Helmet>
        <title>Presented Lessons | Maidan</title>
      </Helmet>
      <div>
        <h1 className="page-heading">Presented Lessons</h1>
        <TeacherPresentationsList
          membershipId={currentUser.currentMembershipId}
        />
      </div>
    </>
  );
};

export default TeacherPresentationsPage;
