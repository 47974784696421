import React from "react";
import { Select, Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import SelectInput from "../SelectInput";
import { createResponsiveFilterLayout } from "../../helpers/responsiveFilterLayoutHelper";

const { Option } = Select;

const DevelopmentRecordFilters = ({
  filters,
  handleChange,
  data,
  isMobile,
  showClassroomFilter,
  showSubjectFilter,
  showChildrenFilter,
  setChildName,
  setChildPhoto,
  childName,
  setPrint,
  reactToPrintFn,
}) => {
  const handlePrint = () => {
    setPrint(true);
    setTimeout(() => {
      reactToPrintFn();
      setPrint(false);
    }, 0);
  };

  const handleChildChange = (childId) => {
    if (!childId) {
      setChildName("");
      handleChange("selectedChildren")(null);
      return;
    }

    const selectedChild = data.children.find(
      (child) => child.child_id === childId
    );
    if (selectedChild) {
      const fullName = [selectedChild.first_name, selectedChild.last_name]
        .filter(Boolean)
        .join(" ");
      setChildName(fullName);
      setChildPhoto(selectedChild.profile_photo_url || "/icons/profile-icon.png")
      handleChange("selectedChildren")(childId);
    }
  };

  const getFilteredChildren = () => {
    return filters.selectedClassroom
      ? data.children.filter(
          (child) => child.classroom_id === filters.selectedClassroom
        )
      : data.children;
  };

  const filterComponents = [
    {
      component: (
        <SelectInput
          placeholder="Classroom"
          value={filters.selectedClassroom}
          onChange={handleChange("selectedClassroom")}
          options={data.classrooms}
        />
      ),
      show: showClassroomFilter,
      desktopSpan: 6,
      mobileSpan: 24,
    },
    {
      component: (
        <Select
          showSearch
          placeholder="Children"
          value={filters.selectedChildren || undefined}
          onChange={handleChildChange}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          style={{ width: "100%" }}
          allowClear
        >
          {getFilteredChildren().map((child) => (
            <Option key={child.child_id} value={child.child_id}>
              {`${child.first_name} ${child.last_name || ""}`}
            </Option>
          ))}
        </Select>
      ),
      show: showChildrenFilter,
      desktopSpan: 6,
      mobileSpan: 12,
    },
    {
      component: (
        <Select
          placeholder="Subject"
          value={filters.selectedSubject || undefined}
          onChange={handleChange("selectedSubject")}
          allowClear
          style={{ width: "100%" }}
        >
          {data.subjects.map((subject) => (
            <Option key={subject.id} value={subject.id}>
              {subject.name}
            </Option>
          ))}
        </Select>
      ),
      show: showSubjectFilter,
      desktopSpan: 6,
      mobileSpan: 12,
    },
    {
      component: (
        <Button
          type="primary"
          icon={<PrinterOutlined />}
          onClick={handlePrint}
          style={{ float: "right" }}
        >
          Print
        </Button>
      ),
      show: Boolean(childName),
      desktopSpan: 6,
      mobileSpan: 24,
    },
  ];

  const { desktopLayout, mobileLayout } =
    createResponsiveFilterLayout(filterComponents);

  return isMobile ? mobileLayout : desktopLayout;
};

export default DevelopmentRecordFilters;
