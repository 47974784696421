import React, { useEffect, useRef } from "react";
import { Form, Input, Button, Typography } from "antd";
import { formFocusHelper } from "../helpers/formFocusHelper";

const { Title } = Typography;

const GradeEditForm = ({ grade = {}, onFinish, refreshGrades, title }) => {
  const ref = useRef(null);

  useEffect(() => {
    return () => formFocusHelper(ref); 
  }, []);

  const handleFinish = (gradeData) => {
    onFinish(gradeData)
      .then(() => {
        refreshGrades();
      })
      .catch((error) => {
        console.error("Error handling grade:", error);
      });
  };

  return (
    <>
      <Title level={2}>{title}</Title>
      <Form
        initialValues={grade}
        onFinish={handleFinish}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please input the grade name." }]}
        >
          <Input ref={ref}/>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {grade.id ? "Update Grade" : "Create Grade"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default GradeEditForm;
