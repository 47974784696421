// client/src/middleware/ability.js
import { defineAbility } from "@casl/ability";

export function defineAbilitiesFor(user) {
  return defineAbility((can) => {
    if (!user) return;

    if (user.role === "Owner") {
      can("manage", "all");
    } else if (user.role === "Admin") {
      can("manage", "all");
    } else if (user.role === "Teacher") {
      can("access", "dashboard");
      can("access", "children");
      can("access", "child-notes");
      can("access", "staff-members");
      can("access", "record-attendance");
      can("access", "attendance-report");
      can("access", "record-keeper");
      can("access", "development-record");
      can("access", "classrooms");
      can("access", "guardians");
      can("access", "subjects");
      can("access", "my-planned-activities");
    } else if (user.role === "Guard") {
      can("record", "guard-attendance");
    }
  });
}
