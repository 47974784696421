import "../../css/record-keeper/MobileProgressOverviewPage.css";

const MobileProgressOverview = ({ masteryData, columns }) => {
  return (
    <div className="mobile-progress-overview">
      {masteryData.map((row, index) => (
        <div className="mobile-data-row" key={row.lesson_id} >
          {row.parent_id === null ? (
            <div className="mobile-data-item">
              <div className="mobile-column-name">Lesson Name</div>
              <div className="mobile-column-data">
                <strong>
                  {index + 1}. {row.lesson_name}
                </strong>
              </div>
            </div>
          ) : (
            columns.map((col) => {
              const data = row[col.dataIndex];
              return (
                <div key={col.key} className="mobile-data-item">
                  <div className="mobile-column-name">{col.title}</div>
                  <div className="mobile-column-data">{col.render(data, row)}</div>
                </div>
              );
            })
          )}
        </div>
      ))}
    </div>
  );
};

export default MobileProgressOverview;
