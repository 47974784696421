import React, { useState, useEffect } from "react";
import { Modal, Table, Input } from "antd";
import { getClassroomsNotMappedToChild } from "../api/SchoolAPI";

const { Search } = Input;

const ManageClassroomsModal = ({
  childId,
  isOpen,
  onClose,
  onAdd,
  mode,
  existingClassrooms,
}) => {
  const [classroomsNotMapped, setClassroomsNotMapped] = useState([]);
  const [selectedClassrooms, setSelectedClassrooms] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (isOpen) {
      const fetchClassrooms = async () => {
        try {
          const classrooms = await getClassroomsNotMappedToChild(childId);
          setClassroomsNotMapped(classrooms);
        } catch (error) {
          console.error(
            "Error fetching classrooms not mapped to child:",
            error
          );
        }
      };
      fetchClassrooms();
    }
  }, [isOpen, childId]);

  const handleAddClassrooms = () => {
    onAdd(selectedClassrooms);
    setSelectedClassrooms([]);
  };

  const handleClassroomSelection = (selectedRowKeys) => {
    setSelectedClassrooms(selectedRowKeys);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredClassrooms =
    mode === "add"
      ? classroomsNotMapped.filter((classroom) =>
          classroom.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : existingClassrooms.filter((classroom) =>
          classroom.name.toLowerCase().includes(searchQuery.toLowerCase())
        );

  const classroomColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedClassrooms,
    onChange: handleClassroomSelection,
  };

  return (
    <Modal
      title={
        mode === "add"
          ? "Add Classrooms to Child"
          : "Remove Classrooms from Child"
      }
      open={isOpen}
      onOk={handleAddClassrooms}
      onCancel={onClose}
      okText={mode === "add" ? "Add to Child" : "Remove from Child"}
    >
      <Search
        placeholder="Search by name"
        allowClear
        onChange={handleSearch}
        style={{ marginBottom: 16 }}
      />
      <Table
        rowSelection={rowSelection}
        columns={classroomColumns}
        dataSource={filteredClassrooms}
        rowKey="id"
        pagination={{ pageSize: 10 }}
      />
    </Modal>
  );
};

export default ManageClassroomsModal;
