import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Drawer, Button, Modal, Input } from "antd";
import { EditOutlined, FileTextOutlined } from "@ant-design/icons";
import ChildEditForm from "./ChildEditForm";
import ImportChildren from "./ImportChildren";
import ChildCardList from "./ChildCardList";
import ChildListFilters from "./ChildListFilters";
import { createChild, updateChild } from "../api/ChildAPI";
import { formatDate, calculateAge } from "../helpers/dateTimeHelper";
import "../css/ChildList.css";
import { getFullName } from "../helpers/childHelper";
import { formatPhoneNumber } from "../helpers/formatHelper";
import { useAbility } from "../hooks/useAbility";
import useIsMobile from "../hooks/useIsMobile";
import { getSortedChildren } from "../helpers/childHelper";
import ChildNotesModal from "./ChildNotesModal";
import requestHandler from "../utils/requestHandler";
import CustomTable from "./CustomTable";

const { Search } = Input;

const ChildList = ({
  children,
  refreshChildren,
  showNewButton = false,
  showClassroomFilter = true,
  showSortOption = false,
  showSearchBar = false,
  showParentInfo = true,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState("ageLowToHigh");
  const [selectedClassroom, setSelectedClassroom] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedChild, setSelectedChild] = useState(null);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const isMobile = useIsMobile();
  const ability = useAbility();

  const handleEditChild = (record) => {
    if (ability.can("update", "children")) {
      setSelectedChild(record);
      setIsDrawerOpen(true);
    }
  };

  const handleNewChild = () => {
    if (ability.can("update", "children")) {
      setSelectedChild({});
      setIsDrawerOpen(true);
    }
  };

  const handleFormFinish = async (childData) => {
    if (selectedChild && selectedChild.id) {
      await requestHandler.execute(
        updateChild(selectedChild.child_id, childData),
        "Child updated.",
        "Failed to update child."
      );
    } else {
      await requestHandler.execute(
        createChild(childData),
        "New child created.",
        "Failed to create child."
      );
    }
    setIsDrawerOpen(false);
    refreshChildren();
  };

  const openChildNotesModal = (child) => {
    setSelectedChild(child);
    setIsNotesModalOpen(true);
  };

  const classrooms = useMemo(() => {
    const uniqueClassrooms = new Map();
    children.forEach((child) => {
      if (child.classrooms) {
        child.classrooms.forEach((classroom) => {
          if (classroom.id) {
            uniqueClassrooms.set(classroom.id, classroom.name);
          }
        });
      }
    });
    return Array.from(uniqueClassrooms.entries());
  }, [children]);

  const grades = useMemo(() => {
    const uniqueGrades = new Map();
    children.forEach((child) => {
      if (child.grade && child.grade.id) {
        uniqueGrades.set(child.grade.id, child.grade.name);
      }
    });
    return Array.from(uniqueGrades.entries()).map(([id, name]) => ({
      id,
      name,
    }));
  }, [children]);

  const filteredChildren = children
    .filter((child) =>
      child.first_name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter((child) =>
      selectedClassroom
        ? child.classrooms.some(
            (classroom) => classroom.id === selectedClassroom
          )
        : true
    )
    .filter((child) =>
      selectedGrade ? child.grade && child.grade.id === selectedGrade : true
    );

  const sortedChildren = getSortedChildren(filteredChildren, sortOption);

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <div>
          <Link to={`/children/${record.child_id}`} className="child-link">
            {getFullName(record)}
          </Link>
          <div style={{ color: "grey", fontSize: "12px" }}>{record.sid}</div>
        </div>
      ),
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
      render: (grade) => (grade ? grade.name : "N/A"),
    },
    {
      title: "Classroom",
      dataIndex: "classrooms",
      key: "classrooms",
      render: (classrooms) =>
        classrooms && classrooms.length > 0
          ? classrooms.map((classroom) => classroom.name).join(", ")
          : "N/A",
    },
    {
      title: "DOB",
      dataIndex: "date_of_birth",
      key: "date_of_birth",
      render: (text) => (
        <div>
          <div>{formatDate(text, "DD-MM-YY")}</div>
          <div>{calculateAge(text)}</div>
        </div>
      ),
    },
    // Conditionally include "Mother" column
    ...(showParentInfo
      ? [
          {
            title: "Mother",
            key: "mother",
            render: (text, record) => (
              <div>
                <div>{record.mother_name}</div>
                <div>
                  <a href={`tel:${record.mother_phone}`}>
                    {formatPhoneNumber(record.mother_phone)}
                  </a>
                </div>
                <div>{record.mother_email}</div>
              </div>
            ),
          },
        ]
      : []),
    // Conditionally include "Father" column
    ...(showParentInfo
      ? [
          {
            title: "Father",
            key: "father",
            render: (text, record) => (
              <div>
                <div>{record.father_name}</div>
                <div>
                  <a href={`tel:${record.father_phone}`}>
                    {formatPhoneNumber(record.father_phone)}
                  </a>
                </div>
                <div>{record.father_email}</div>
              </div>
            ),
          },
        ]
      : []),
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <div>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEditChild(record)}
            disabled={!ability.can("update", "children")}
            style={{ marginRight: 8 }}
          />
          <Button
            icon={<FileTextOutlined />}
            onClick={() => openChildNotesModal(record)}
          />
        </div>
      ),
      width: 120,
    },
  ];

  const searchBar = (
    <Search
      placeholder="Search by name"
      allowClear
      onChange={(e) => setSearchQuery(e.target.value)}
      value={searchQuery}
    />
  );

  return (
    <div>
      <ChildListFilters
        showNewButton={showNewButton}
        showSearchBar={showSearchBar}
        showClassroomFilter={showClassroomFilter}
        showSortOption={showSortOption}
        searchBar={searchBar}
        sortOption={sortOption}
        onSortChange={setSortOption}
        classrooms={classrooms}
        selectedClassroom={selectedClassroom}
        onClassroomChange={setSelectedClassroom}
        grades={grades}
        selectedGrade={selectedGrade}
        onGradeChange={setSelectedGrade}
        onNewChild={handleNewChild}
        onImportChildren={() => setIsModalOpen(true)}
        ability={ability}
        isMobile={isMobile}
      />

      <div className="table-container">
        {isMobile ? (
          <ChildCardList
            children={sortedChildren}
            onChildClick={handleEditChild}
            openChildNotesModal={openChildNotesModal}
            ability={ability}
          />
        ) : (
          <CustomTable
            columns={columns}
            dataSource={sortedChildren}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
      </div>

      <Drawer
        width={350}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        destroyOnClose={true}
      >
        <ChildEditForm
          child={selectedChild}
          onFinish={handleFormFinish}
          refreshChildren={refreshChildren}
          title={
            selectedChild && selectedChild.id
              ? getFullName(selectedChild)
              : "New Child"
          }
        />
      </Drawer>

      <Modal
        title="Import Children"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <ImportChildren />
      </Modal>

      {isNotesModalOpen && (
        <ChildNotesModal
          open={isNotesModalOpen}
          onClose={() => setIsNotesModalOpen(false)}
          child={selectedChild}
        />
      )}
    </div>
  );
};

export default ChildList;
