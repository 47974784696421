// src/config/sidebarStructure.js
import {
  SmileOutlined,
  SettingOutlined,
  ReadOutlined,
} from "@ant-design/icons";

export const sidebarStructure = {
  Owner: [
    { type: "item", key: "dashboard" },
    {
      type: "submenu",
      key: "children-menu",
      label: "Children",
      icon: <SmileOutlined />,
      children: [
        "children",
        "record-keeper",
        "progress-overview",
        "development-record",
        "record-attendance",
        "attendance-report",
        "presented-lessons",
        "lesson-editor",
      ],
    },
    { type: "item", key: "guardians" },
    { type: "item", key: "staff-members" },
    { type: "item", key: "security-guards" },
    {
      type: "submenu",
      key: "library-menu",
      label: "Library",
      icon: <ReadOutlined />,
      children: ["books", "authors", "collections", "publishers", "categories"],
    },
    {
      type: "submenu",
      key: "admin-menu",
      label: "Admin",
      icon: <SettingOutlined />,
      children: ["classrooms", "grades", "school-info"],
    },
  ],
  Admin: [
    { type: "item", key: "dashboard" },
    { type: "item", key: "children" },
    { type: "item", key: "staff-members" },
    { type: "item", key: "security-guards" },
    {
      type: "submenu",
      key: "admin-menu",
      label: "Admin",
      icon: <SettingOutlined />,
      children: ["classrooms", "grades", "school-info"],
    },
  ],
  Teacher: [
    { type: "item", key: "dashboard" },
    { type: "item", key: "record-keeper" },
    { type: "item", key: "progress-overview" },
    { type: "item", key: "development-record" },
    { type: "item", key: "record-attendance" },
    { type: "item", key: "attendance-report" },
    { type: "item", key: "presented-lessons" },
    { type: "item", key: "children" },
    { type: "item", key: "staff-members" },
    { type: "item", key: "lesson-editor" },
  ],
};
