// src/api/SubjectAPI.js

import axios from "axios";

const baseURL = "/subjects";

const api = axios.create({
  baseURL,
});

export const getSubjects = async () => {
  try {
    const response = await api.get("/");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createSubject = async (subjectData) => {
  try {
    const response = await api.post("/", subjectData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateSubject = async (subjectId, subjectData) => {
  try {
    const response = await api.put(`/${subjectId}`, subjectData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getLessonsBySubject = async (subjectId) => {
  try {
    const response = await api.get(`/${subjectId}/lessons`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createLesson = async (lessonData) => {
  try {
    const response = await api.post(`/lessons`, lessonData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateLesson = async (lessonId, lessonData) => {
  try {
    const response = await api.put(`/lessons/${lessonId}`, lessonData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteLesson = async (lessonId) => {
  try {
    const response = await api.delete(`/lessons/${lessonId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPossibleParents = async (subjectId, lessonId) => {
  try {
    const response = await api.get(
      `/${subjectId}/lessons/${lessonId}/possible-parents`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPossibleSequences = async (subjectId, parentId, isNew) => {
  try {
    const response = await api.get(
      `/lessons/${subjectId}/${parentId}/possible-sequences`,
      {
        params: { isNew },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
