import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import LessonList from "../components/LessonList";
import LoadingSpinner from "../components/LoadingSpinner";
import { getLessonsBySubject } from "../api/SubjectAPI";
import { useParams } from "react-router-dom";

const SubjectPage = () => {
  const { subjectId } = useParams();
  const [subjectData, setSubjectData] = useState({
    subject_name: "",
    lessons: [],
  });
  const [loading, setLoading] = useState(true);

  const refreshLessons = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getLessonsBySubject(subjectId);
      setSubjectData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [subjectId]);

  useEffect(() => {
    refreshLessons();
  }, [refreshLessons]);

  return (
    <div>
      <Helmet>
        <title>
          {subjectData ? `${subjectData.subject_name} | Maidan` : "Loading..."}
        </title>
      </Helmet>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <h1 className="page-heading">{subjectData.subject_name}</h1>
          <LessonList
            subjectId={subjectId}
            lessons={subjectData.lessons}
            refreshLessons={refreshLessons}
            showNewButton={true}
            showSearchBar={true}
          />
        </>
      )}
    </div>
  );
};

export default SubjectPage;
