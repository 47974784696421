// src/api/RecordKeeperAPI.js

import axios from "axios";

const baseURL = "/record-keeper";

const api = axios.create({
  baseURL,
});

export const getInitialSetupData = async () => {
  try {
    const response = await api.get("/initial-setup-data");
    return response.data;
  } catch (error) {
    console.error("Error fetching initial data:", error);
    throw error;
  }
};

export const getMyPlannedActivities = async () => {
  try {
    const response = await api.get("/my-planned-activities");
    return response.data;
  } catch (error) {
    console.error("Error fetching planned activities:", error);
    throw error;
  }
};

export const getProgressOverviewByClassroomAndSubject = async (
  classroomId,
  subjectId,
  gradeIds = []
) => {
  try {
    const response = await api.get(
      `/progress-overview/classroom/${classroomId}/subject/${subjectId}`,
      {
        params: {
          gradeIds: gradeIds.length > 0 ? gradeIds.join(",") : undefined,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching mastery overview:", error);
    throw error;
  }
};

export const getMasteryLevelsByClassroomAndSubject = async (
  classroomId,
  subjectId
) => {
  try {
    const response = await api.get(
      `/classroom/${classroomId}/subject/${subjectId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const upsertChildMastery = async (
  childId,
  lessonId,
  masteryLevelId,
  date
) => {
  try {
    const response = await api.post("/upsert", {
      childId,
      lessonId,
      masteryLevelId,
      date,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createNote = async (noteData) => {
  try {
    const response = await api.post("/notes", noteData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateNote = async (noteId, noteData) => {
  try {
    const response = await api.put(`/notes/${noteId}`, noteData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteNote = async (noteId) => {
  try {
    const response = await api.delete(`/notes/${noteId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getNotes = async (childId, lessonId) => {
  try {
    const response = await api.get(`/notes`, {
      params: {
        childId,
        lessonId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMasteryLevelsByChild = async (childId) => {
  try {
    const response = await api.get(`/children/${childId}/mastery-levels`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
