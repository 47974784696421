import axios from "axios";

const baseURL = "/schools/grades";

const api = axios.create({
  baseURL,
});

// API to create a new grade
export const createGrade = async (gradeData) => {
  try {
    const response = await api.post("/", gradeData);
    return response.data;
  } catch (error) {
    console.error("Error creating grade:", error);
    throw error;
  }
};

// API to update an existing grade
export const updateGrade = async (gradeId, gradeData) => {
  try {
    const response = await api.put(`/${gradeId}`, gradeData);
    return response.data;
  } catch (error) {
    console.error("Error updating grade:", error);
    throw error;
  }
};

// API to get a grade by its ID
export const getGradeById = async (gradeId) => {
  try {
    const response = await api.get(`/${gradeId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching grade:", error);
    throw error;
  }
};

// API to get all grades
export const getGrades = async () => {
  try {
    const response = await api.get("/");
    return response.data;
  } catch (error) {
    console.error("Error fetching grades:", error);
    throw error;
  }
};

// API to get all children of a specific grade
export const getChildrenInGrade = async (gradeId) => {
  try {
    const response = await api.get(`/${gradeId}/children`);
    return response.data;
  } catch (error) {
    console.error("Error fetching children in grade:", error);
    throw error;
  }
};

// API to get all children in a specific (classroom, grade) combination
export const getChildrenInClassroomGrade = async (classroomId, gradeId) => {
  try {
    const response = await api.get(
      `/grades/${gradeId}/classrooms/${classroomId}/children`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching children in classroom and grade:", error);
    throw error;
  }
};

// API to map a child to a grade
export const mapChildToGrade = async (gradeId, childId) => {
  try {
    const response = await api.post(`/${gradeId}/children/${childId}/map`);
    return response.data;
  } catch (error) {
    console.error("Error mapping child to grade:", error);
    throw error;
  }
};

// API to unmap a child from a grade
export const unmapChildFromGrade = async (gradeId, childId) => {
  try {
    const response = await api.delete(`/${gradeId}/children/${childId}/unmap`);
    return response.data;
  } catch (error) {
    console.error("Error unmapping child from grade:", error);
    throw error;
  }
};

export const getChildrenNotInGrade = async (gradeId) => {
  try {
    const response = await api.get(`/${gradeId}/children-not-in-grade`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
