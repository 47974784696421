import React, { useEffect, useState } from "react";
import moment from "moment";
import { Table, Tabs } from "antd";
import { CheckCircleFilled, QuestionCircleOutlined } from "@ant-design/icons";
import {
  getMyPlannedActivities,
  upsertChildMastery,
} from "../../api/RecordKeeperAPI";
import { getFullName } from "../../helpers/childHelper";
import { trackEvent } from "../../utils/mixpanel";
import MyPlannedActivitiesCardList from "./MyPlannedActivitiesCardList";
import LoadingSpinner from "../../components/LoadingSpinner";
import "../../css/MyPlannedActivities.css";

const MyPlannedActivities = () => {
  const [loading, setLoading] = useState(true);
  const [plannedActivities, setPlannedActivities] = useState(null);
  const [activeTab, setActiveTab] = useState("byActivity");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const data = await getMyPlannedActivities();
        setPlannedActivities(data);
        trackEvent("MyPlannedActivities: Page Loaded", {
          activitiesCount: data?.activities
            ? Object.keys(data.activities).length
            : 0,
          viewMode: activeTab === "byActivity" ? "By Presentation" : "By Child",
        });
      } catch (error) {
        console.error("Error fetching planned activities:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchActivities();
  }, [activeTab]);

  const sortChildren = (children) => {
    return children.sort((a, b) =>
      getFullName(a).localeCompare(getFullName(b))
    );
  };

  const sortActivities = (activities) => {
    return activities.sort((a, b) => a.lesson_id.localeCompare(b.lesson_id));
  };

  const handleToggleMasteryLevel = async (
    childId,
    lessonId,
    currentMasteryLevelId
  ) => {
    const newMasteryLevelId =
      currentMasteryLevelId === plannedActivities.presented_mastery_level_id
        ? plannedActivities.planned_mastery_level_id
        : plannedActivities.presented_mastery_level_id;

    const newMasteryLevelName =
      newMasteryLevelId === plannedActivities.presented_mastery_level_id
        ? "Presented"
        : "Planned";

    try {
      await upsertChildMastery(
        childId,
        lessonId,
        newMasteryLevelId,
        moment().format("YYYY-MM-DD")
      );

      trackEvent("MyPlannedActivities: Mastery Level Toggled", {
        childId,
        lessonId,
        newMasteryLevelId,
        newMasteryLevelName,
        viewMode: activeTab === "byActivity" ? "By Presentation" : "By Child",
      });

      const updatedActivities = await getMyPlannedActivities();

      // Sort children for each activity
      Object.values(updatedActivities.activities).forEach((activity) => {
        activity.children = sortChildren(activity.children);
      });

      setPlannedActivities(updatedActivities);
    } catch (error) {
      console.error("Error updating mastery level:", error);
    }
  };

  const renderMasteryIcon = (isPresented, onClick) =>
    isPresented ? (
      <CheckCircleFilled
        style={{ color: "green", marginRight: 8, cursor: "pointer" }}
        onClick={onClick}
      />
    ) : (
      <QuestionCircleOutlined
        style={{ marginRight: 8, cursor: "pointer" }}
        onClick={onClick}
      />
    );

  const columnsByActivity = [
    {
      title: "Presentation",
      dataIndex: "lessonName",
      key: "lessonName",
      render: (text, record) => (
        <>
          <div>{text}</div>
          {record.parentLessonName && (
            <div className="parent-lesson-name">{record.parentLessonName}</div>
          )}
        </>
      ),
    },
    {
      title: "Children",
      dataIndex: "childrenData",
      key: "childrenData",
      className: "children-column",
      render: (childrenData, record) =>
        childrenData.map((child) => (
          <div key={child.child_id}>
            {renderMasteryIcon(
              child.mastery_level_id ===
                plannedActivities.presented_mastery_level_id,
              () =>
                handleToggleMasteryLevel(
                  child.child_id,
                  record.key,
                  child.mastery_level_id
                )
            )}
            {getFullName(child)}
          </div>
        )),
    },
  ];

  const columnsByChild = [
    {
      title: "Child",
      dataIndex: "childName",
      key: "childName",
      className: "child-column",
    },
    {
      title: "Presentations",
      dataIndex: "activities",
      key: "activities",
      render: (activities) =>
        sortActivities(activities).map((activity, index) => (
          <div key={index} className="child-activity">
            {renderMasteryIcon(
              activity.mastery_level_id ===
                plannedActivities.presented_mastery_level_id,
              () =>
                handleToggleMasteryLevel(
                  activity.child_id,
                  activity.lesson_id,
                  activity.mastery_level_id
                )
            )}
            {activity.name}
            {activity.parentLessonName && (
              <div className="parent-lesson-name">
                {activity.parentLessonName}
              </div>
            )}
          </div>
        )),
    },
  ];

  const tableDataByActivity = plannedActivities?.activities
    ? Object.entries(plannedActivities.activities).map(
        ([lessonId, lessonData]) => ({
          key: lessonId,
          lessonName: lessonData.name,
          parentLessonName: lessonData.parent_lesson_name,
          childrenData: sortChildren(lessonData.children),
        })
      )
    : [];

  const tableDataByChild = [];
  const childMap = new Map();

  if (plannedActivities?.activities) {
    Object.entries(plannedActivities.activities).forEach(
      ([lessonId, lessonData]) => {
        lessonData.children.forEach((child) => {
          const childKey = `${child.child_id}`;
          if (!childMap.has(childKey)) {
            childMap.set(childKey, {
              key: childKey,
              childName: `${getFullName(child)}`,
              activities: [],
            });
          }
          childMap.get(childKey).activities.push({
            name: lessonData.name,
            parentLessonName: lessonData.parent_lesson_name,
            mastery_level_id: child.mastery_level_id,
            child_id: child.child_id,
            lesson_id: lessonId,
          });
        });
      }
    );
    tableDataByChild.push(...childMap.values());
    // Sort activities for each child
    tableDataByChild.forEach((child) => {
      child.activities = sortActivities(child.activities);
    });
    // Sort children
    tableDataByChild.sort((a, b) => a.childName.localeCompare(b.childName));
  }

  const tabItems = [
    {
      label: "By Presentation",
      key: "byActivity",
      children: isMobile ? (
        <MyPlannedActivitiesCardList
          plannedActivities={plannedActivities}
          view="activity"
          handleToggleMasteryLevel={handleToggleMasteryLevel}
          sortChildren={sortChildren}
        />
      ) : (
        <Table
          columns={columnsByActivity}
          dataSource={tableDataByActivity}
          pagination={false}
        />
      ),
    },
    {
      label: "By Child",
      key: "byChild",
      children: isMobile ? (
        <MyPlannedActivitiesCardList
          plannedActivities={plannedActivities}
          view="child"
          handleToggleMasteryLevel={handleToggleMasteryLevel}
          sortActivities={sortActivities}
        />
      ) : (
        <Table
          columns={columnsByChild}
          dataSource={tableDataByChild}
          pagination={false}
        />
      ),
    },
  ];

  return (
    <div className="my-planned-activities-widget">
      <h2>My Planned Presentations</h2>

      {loading ? (
        <LoadingSpinner tip="Loading data..." />
      ) : (
        <Tabs
          defaultActiveKey="byActivity"
          onChange={setActiveTab}
          items={tabItems}
        />
      )}
    </div>
  );
};

export default MyPlannedActivities;
