import React from "react";
import { Card, Row, Col, Typography, Tag, Button, Dropdown, Space } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { formatDate } from "../helpers/dateTimeHelper";
import { formatPhoneNumber } from "../helpers/formatHelper";
import { getFullName } from "../helpers/childHelper";
import "../css/MembershipInfo.css";

const { Text } = Typography;

const MembershipInfo = ({
  membership,
  showPhone = false,
  showEmail = false,
  id = null,
  classrooms = [],
  menuItems = null,
}) => {
  return (
    <div className="membership-info">
      <div className="page-header">
        <div className="info-header">
          <img
            src={membership.profile_photo_url || "/icons/profile-icon.png"}
            alt="Profile"
            className="profile-image"
          />
          <div className="info-text">
            <h1 className="page-heading">{getFullName(membership)}</h1>
            <div className="info-bubbles">
              {id && <Tag color="blue">{id}</Tag>}
              {classrooms.map((classroom, index) => (
                <Tag key={index} color="green">
                  {classroom.name}
                </Tag>
              ))}
              {membership.grade?.grade_name && (
                <Tag color="orange">{membership.grade.grade_name}</Tag>
              )}
            </div>
          </div>
        </div>
        {menuItems && (
          <Dropdown menu={{ items: menuItems }}>
            <Button>
              <Space>
                Manage
                <MoreOutlined />
              </Space>
            </Button>
          </Dropdown>
        )}
      </div>

      <Card className="info-card">
        <Row gutter={[16, 24]}>
          {showPhone && (
            <Col xs={24} sm={12}>
              <Text strong>Phone:</Text>{" "}
              <Text>
                <a href={`tel:${membership.phone_number}`}>
                  {formatPhoneNumber(membership.phone_number) ||
                    "Not available"}
                </a>
              </Text>
            </Col>
          )}
          {showEmail && (
            <Col xs={24} sm={12}>
              <Text strong>Email:</Text>{" "}
              <Text>{membership.email || "Not available"}</Text>
            </Col>
          )}
          <Col xs={24} sm={12}>
            <Text strong>Date of Birth:</Text>{" "}
            <Text>
              {membership.date_of_birth
                ? formatDate(membership.date_of_birth, "DD-MM-YY")
                : "Not available"}
            </Text>
          </Col>
          <Col xs={24} sm={12}>
            <Text strong>Blood Group:</Text>{" "}
            <Text>{membership.blood_group || "Not available"}</Text>
          </Col>
          <Col xs={24} sm={12}>
            <Text strong>Date of Joining:</Text>{" "}
            <Text>
              {membership.date_of_joining
                ? formatDate(membership.date_of_joining, "DD-MM-YY")
                : "Not available"}
            </Text>
          </Col>
          <Col xs={24} sm={12}>
            <Text strong>Gender:</Text>{" "}
            <Text>{membership.gender || "Not available"}</Text>
          </Col>
        </Row>
        <div className="membership-address">
          <Text strong>Address:</Text>{" "}
          <Text>{membership.current_address || "Not available"}</Text>
        </div>
      </Card>
    </div>
  );
};

export default MembershipInfo;
