import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Alert } from "antd";
import { useAuth } from "../contexts/AuthContext";
import SchoolNameOnboardingForm from "../components/SchoolNameOnboardingForm";
import LoadingSpinner from "../components/LoadingSpinner";
import "../css/UserRoleSelectionPage.css";

const UserRoleSelectionPage = ({ currentUser }) => {
  const { handleLogout } = useAuth();
  const [role, setRole] = useState("");
  const [message, setMessage] = useState(null);
  let loading = false;
  
  useEffect(()=>{
    message && console.log(`${message?.type}: ${message?.text}`)
  },[message])

  const handleRoleChange = (value) => {
    setRole(value);
    setMessage(null);
  };

  const getButtonClassName = (buttonRole) =>
    `role-button ${role === buttonRole ? "active-role" : ""}`;

  const renderRoleContent = () => {
    if (role === "Founder") {
      return <SchoolNameOnboardingForm setMessage={setMessage} />;
    } else if (role === "Parent" || role === "Staff") {
      const infoMessage = "Please contact your school to set up your account.";
      return (
        <Alert
          message={infoMessage}
          type="info"
          showIcon
          className="user-role-alert"
        />
      );
    }
    return null;
  };

  return (
    <div>
      <Helmet>
        <title>Onboarding | Maidan</title>
      </Helmet>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="user-role-selection-page">
          <header className="onboarding-header">
            <Button
              onClick={handleLogout}
              type="link"
              className="logout-button"
            >
              Logout
            </Button>
          </header>
          <div className="user-role-selection-container">
            <h2 className="heading">
              Hi {currentUser.display_name},<br /> What's your role?
            </h2>
            <div className="role-buttons-container">
              {["Founder", "Parent", "Staff"].map((buttonRole) => (
                <Button
                  key={buttonRole}
                  onClick={() => handleRoleChange(buttonRole)}
                  className={getButtonClassName(buttonRole)}
                >
                  {buttonRole}
                </Button>
              ))}
            </div>
            {renderRoleContent()}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserRoleSelectionPage;
