import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Drawer, Button, Input, Select, Dropdown, Modal } from "antd";
import {
  PlusOutlined,
  CheckCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import EmployeeEditForm from "./EmployeeEditForm";
import ImportEmployees from "./ImportEmployees";
import useIsMobile from "../hooks/useIsMobile";
import EmployeeCardList from "./EmployeeCardList";
import { useAbility } from "../hooks/useAbility";
import { createEmployee, updateEmployee } from "../api/EmployeeAPI";
import { getFullName } from "../helpers/childHelper";
import { formatPhoneNumber } from "../helpers/formatHelper";
import { sendWelcomeEmail } from "../api/MembershipsAPI";
import { createResponsiveFilterLayout } from "../helpers/responsiveFilterLayoutHelper";
import requestHandler from "../utils/requestHandler";
import CustomTable from "./CustomTable";

const { Search } = Input;
const { Option } = Select;

const NewButton = ({ onClick, onImport, ability }) => {
  const menuItems = [
    {
      key: "new-employee",
      label: "New Staff Member",
      onClick: onClick,
    },
    {
      key: "import-employees",
      label: "Import",
      onClick: onImport,
    },
  ];

  return (
    <Dropdown menu={{ items: menuItems }} trigger={["click"]}>
      <Button type="default" icon={<PlusOutlined />} disabled={!ability.can("create", "employee")} />
    </Dropdown>  
  );
}

const SearchBar = ({ value, onChange }) => (
  <Search
    placeholder="Search by name"
    allowClear
    value={value}
    onChange={onChange}
  />
);

const TitleFilter = ({ titles, value, onChange }) => (
  <Select
    defaultValue=""
    style={{ width: 150 }}
    value={value}
    onChange={onChange}
  >
    <Option value="">All Titles</Option>
    {titles.map((title) => (
      <Option key={title} value={title}>
        {title}
      </Option>
    ))}
  </Select>
);

const SortSelect = ({ value, onChange }) => (
  <Select
    defaultValue="alphabetic"
    style={{ width: 150 }}
    value={value}
    onChange={onChange}
  >
    <Option value="alphabetic">A-Z</Option>
    <Option value="employee_id">ID</Option>
    <Option value="title">Title</Option>
  </Select>
);

const EmployeeList = ({
  employees,
  roles,
  refreshEmployees,
  showNewButton = false,
  showSortOption = false,
  showSearchBar = false,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState("alphabetic");
  const [titleFilter, setTitleFilter] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const isMobile = useIsMobile();
  const ability = useAbility();

  const uniqueTitles = Array.from(
    new Set(employees.map((employee) => employee.employee_title))
  )
    .filter((title) => title)
    .sort((a, b) => a.localeCompare(b));

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSortChange = (value) => {
    setSortOption(value);
  };

  const handleTitleFilterChange = (value) => {
    setTitleFilter(value);
  };

  const sortEmployees = (employees) => {
    switch (sortOption) {
      case "alphabetic":
        return employees.sort((a, b) =>
          a.first_name.localeCompare(b.first_name)
        );
      case "title":
        return employees.sort((a, b) =>
          (a.employee_title || "").localeCompare(b.employee_title || "")
        );
      case "employee_id":
        return employees.sort((a, b) => a.eid.localeCompare(b.eid));
      default:
        return employees;
    }
  };

  const filteredEmployees = sortEmployees(
    employees
      .filter((employee) =>
        employee.first_name.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .filter((employee) =>
        titleFilter ? employee.employee_title === titleFilter : true
      )
  );

  const handleEditEmployee = (record) => {
    if (ability.can("update", "employee")) {
      setSelectedEmployee(record);
      setIsDrawerOpen(true);
    }
  };

  const handleNewEmployee = () => {
    setSelectedEmployee({});
    setIsDrawerOpen(true);
  };

  const handleFormFinish = async (employeeData) => {
    if (selectedEmployee && selectedEmployee.id) {
      await requestHandler.execute(
        updateEmployee(selectedEmployee.employee_id, employeeData),
        "Employee updated.",
        "Failed to update employee."
      );
    } else {
      await requestHandler.execute(
        createEmployee(employeeData),
        "New employee created.",
        "Failed to create employee."
      );
    }
    setIsDrawerOpen(false);
    refreshEmployees();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <div>
          <Link to={`/staff-members/${record.employee_id}`}>
            {getFullName(record)}
          </Link>
          <div style={{ color: "grey", fontSize: "12px" }}>{record.eid}</div>
        </div>
      ),
    },
    {
      title: "Title",
      dataIndex: "employee_title",
      key: "employee_title",
    },
    ...(ability.can("create", "employee")
      ? [
          {
            title: "Role",
            dataIndex: "role",
            key: "role",
            width: 150,
          },
        ]
      : []),
    {
      title: "Contact",
      dataIndex: "phone_number",
      key: "contact",
      render: (text, record) => (
        <div>
          <a href={`tel:${record.phone_number}`}>
            {formatPhoneNumber(record.phone_number)}
          </a>
          <br />
          {record.email && (
            <>
              {record.user_id && (
                <CheckCircleOutlined
                  style={{ color: "green", marginRight: 8 }}
                />
              )}
              {record.email}
              {!record.user_id && (
                <div style={{ marginTop: 8 }}>
                  <Button
                    type="link"
                    style={{ paddingLeft: 0 }}
                    onClick={async (event) => {
                      event.stopPropagation();
                      await requestHandler.execute(
                        sendWelcomeEmail(record.first_name, record.email),
                        "Invite resent.",
                        "Failed to resend the invite."
                      );
                    }}
                  >
                    Resend Invite
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      ),
    },
    {
      key: "action",
      width: 50,
      render: (_, record) => (
        <Button
          icon={<EditOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            handleEditEmployee(record);
          }}
          disabled={!ability.can("update", "employee")}
        />
      ),
    },
  ];

  const filterComponents = [
    {
      component: (
        <NewButton
          onClick={handleNewEmployee}
          onImport={() => setIsModalOpen(true)}
          ability={ability}
        />
      ),
      show: showNewButton && ability.can("create", "employee"),
      desktopSpan: 1,
      mobileSpan: 3,
    },
    {
      component: <SearchBar value={searchQuery} onChange={handleSearch} />,
      show: showSearchBar,
      desktopSpan: 10,
      mobileSpan: 21,
    },
    {
      component: (
        <TitleFilter
          titles={uniqueTitles}
          value={titleFilter}
          onChange={handleTitleFilterChange}
        />
      ),
      show: showSortOption,
      desktopSpan: 3,
      mobileSpan: 12,
    },
    {
      component: <SortSelect value={sortOption} onChange={handleSortChange} />,
      show: showSortOption,
      desktopSpan: 2,
      mobileSpan: 12,
    },
  ];

  const { desktopLayout, mobileLayout } =
    createResponsiveFilterLayout(filterComponents);

  return (
    <div>
      {isMobile ? mobileLayout : desktopLayout}
      <div className="table-container">
        {isMobile ? (
          <EmployeeCardList
            employees={filteredEmployees.map(employee => ({ ...employee, key: employee.employee_id }))}
            onEmployeeClick={handleEditEmployee}
            ability={ability}
          />
        ) : (
          <CustomTable columns={columns} dataSource={filteredEmployees.map(employee => ({ ...employee, key: employee.employee_id }))} />
        )}
      </div>

      <Drawer
        width={350}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedEmployee(null);
        }}
        open={isDrawerOpen}
        closable={false}
      >
        {isDrawerOpen && (
          <EmployeeEditForm
            employee={selectedEmployee}
            roles={roles}
            onFinish={handleFormFinish}
            refreshEmployees={refreshEmployees}
            title={
              selectedEmployee && selectedEmployee.id
                ? getFullName(selectedEmployee)
                : "New Staff Member"
            }
          />
        )}
      </Drawer>

      <Modal
        title="Import Staff"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <ImportEmployees />
      </Modal>
    </div>
  );
};

export default EmployeeList;
