import React, { useEffect, useState } from "react";
import { Table, Alert, Tag, Select, DatePicker } from "antd";
import { getPresentations } from "../../api/EmployeeAPI";
import LoadingSpinner from "../LoadingSpinner";
import { createResponsiveFilterLayout } from "../../helpers/responsiveFilterLayoutHelper";
import "../../css/record-keeper/PresentedLessons.css";

const {Option} = Select;

const TeacherPresentationsList = ({ membershipId, classroomId = null }) => {
  const [presentations, setPresentations] = useState([]);
  const [selectedLesson,setSelectedLesson] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  let childKey = 1;

  useEffect(() => {
    const fetchPresentations = async () => {
      try {
        setLoading(true);
        const response = await getPresentations(membershipId, classroomId);
        setPresentations(response);
      } catch (err) {
        setError("Failed to load presentations data.");
      } finally {
        setLoading(false);
      }
    };

    fetchPresentations();
  }, [membershipId, classroomId]);

  const DateFilter = () => (
    <DatePicker
        className="date-picker"
        onChange={(date,dateString)=>setSelectedDate(date ? {date,dateString} : null)}
        value={selectedDate?.date} 
        style={{ width: "100%" }}
      />
  )

  const LessonFilter = () => (
    <Select
        className="filter-select"
        placeholder="Select a lesson"
        style={{ width: "100%" }}
        onChange={(value)=>setSelectedLesson(value || null)}
        value={selectedLesson}
        allowClear
      >
        {uniqueLessons.map((lesson) => (
          <Option key={lesson.id} value={lesson.id}>
            {lesson.name}
          </Option>
        ))}
      </Select>
  );

  const filterComponents = [
    { component: <DateFilter />, desktopSpan: 4, mobileSpan: 8 },
    { component: <LessonFilter />, desktopSpan: 4, mobileSpan: 8 }
   ];

  const getUniqueLessons = () => {
    const lessonsMap = new Map();
    presentations.forEach((presentation) => {
      const lesson = presentation.lesson;
      if (!lessonsMap.has(lesson.id)) {
        lessonsMap.set(lesson.id, lesson);
      }
    });

    return Array.from(lessonsMap.values());
  };

  const uniqueLessons = getUniqueLessons();

  const { desktopLayout } = createResponsiveFilterLayout(filterComponents);

  const columns = [
    {
      title: "Date",
      dataIndex: "presentation_date",
      key: "presentation_date",
      render: (date) => <div>{new Date(date).toLocaleDateString()}</div>,
    },
    {
      title: "Lesson",
      dataIndex: "lesson",
      key: "lesson",
      render: (lesson) => (
        <div>
          <strong>{lesson.name}</strong>
          <div style={{ color: "grey" }}>
            {lesson.parent_name ? `Parent: ${lesson.parent_name}` : "No Parent"}
          </div>
          <div style={{ color: "grey" }}>Subject: {lesson.subject_name}</div>
        </div>
      ),
    },
    {
      title: "Children",
      dataIndex: "children",
      key: "children",
      render: (children) =>
        Array.isArray(children) && children.length > 0 ? (
          children.map((child) => (
            <Tag key={`child-${childKey++}`}>
              {child.first_name} {child.last_name}
            </Tag>
          ))
        ) : (
          <span>No children</span>
        ),
    },
  ];

  return (
    <div>
      <div className="filters">{desktopLayout}</div>
      {loading ? (
        <LoadingSpinner/>
      ) : error ? (
        <Alert message={error} type="error" showIcon />
      ) : presentations.length === 0 ? (
        <Alert
          message="No presentations found for this teacher."
          type="info"
          showIcon
        />
      ) : (
        <Table
          columns={columns}
          dataSource = {
            presentations.map((presentation, index) => ({
            ...presentation,
            key: `${presentation.presentation_date}-${presentation.lesson.id}-${index}`,
          }))
          .filter((presentation) => {
            const lessonMatch = selectedLesson ? presentation.lesson.id === selectedLesson : true;
            const dateMatch = selectedDate 
                              ? new Date(presentation.presentation_date).toLocaleDateString()===new Date(selectedDate.dateString).toLocaleDateString() 
                              : true;
            return lessonMatch && dateMatch;
          })}
          pagination={{ pageSize: 10 }}
          bordered
        />
      )}
    </div>
  );
};

export default TeacherPresentationsList;
