import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import GradeList from "../components/GradeList";
import LoadingSpinner from "../components/LoadingSpinner";
import { getGrades } from "../api/GradeAPI";

const GradesPage = () => {
  const [grades, setGrades] = useState([]);
  const [loading, setLoading] = useState(true);

  const refreshGrades = async () => {
    setLoading(true);
    try {
      const data = await getGrades();
      setGrades(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshGrades();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Grades | Maidan</title>
      </Helmet>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <h1 className="page-heading">Grades</h1>
          <div>
            <GradeList
              grades={grades}
              refreshGrades={refreshGrades}
              showNewButton={true}
              showSearchBar={true}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default GradesPage;
