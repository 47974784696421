import React, { useEffect, useRef } from "react";
import { Form, Input, Button, Typography, Radio } from "antd";
import BloodGroupDropdown from "./BloodGroupDropdown";
import { formatDate } from "../helpers/dateTimeHelper";
import { formFocusHelper } from "../helpers/formFocusHelper";

const { TextArea } = Input;
const { Title } = Typography;

const GuardianEditForm = ({
  guardian = {},
  onFinish,
  refreshGuardians,
  title,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    return () => formFocusHelper(ref); 
  }, []);

  const handleFinish = (guardianData) => {
    onFinish(guardianData)
      .then(() => {
        refreshGuardians();
      })
      .catch((error) => {
        console.error("Error handling guardian:", error);
      });
  };

  return (
    <>
      <Title level={2}>{title}</Title>
      <Form
        initialValues={{
          ...guardian,
          date_of_birth: guardian.date_of_birth
            ? formatDate(guardian.date_of_birth)
            : null,
        }}
        onFinish={handleFinish}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          name="first_name"
          label="First Name"
          rules={[
            { required: true, message: "Please input the full name." },
            { pattern: /^[A-Za-z\s]+$/, message: "First name must contain only letters." }

          ]}
        >
          <Input ref={ref}/>
        </Form.Item>

        <Form.Item 
          name="last_name" 
          label="Last Name"
          rules={[
            { pattern: /^[A-Za-z\s]*$/, message: "Last name must contain only letters." }
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[{ type: "email", message: "Please input a valid email." }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone_number"
          label="Phone Number"
          rules={[
            {
              pattern: /^\d{10}$/,
              message: "The phone number must be 10 digits.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="date_of_birth" label="Date of Birth">
          <Input type="date" />
        </Form.Item>
        <Form.Item name="gender" label="Gender">
          <Radio.Group>
            <Radio value="Male">Male</Radio>
            <Radio value="Female">Female</Radio>
            <Radio value="Other">Other</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="current_address" label="Current Address">
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item name="permanent_address" label="Permanent Address">
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item name="blood_group" label="Blood Group">
          <BloodGroupDropdown />
        </Form.Item>
        <Form.Item name="occupation" label="Occupation">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {guardian.id ? "Update Guardian" : "Create Guardian"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default GuardianEditForm;
