import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Table, Row, Col } from "antd";
import { useAuth } from "../contexts/AuthContext";
import ClassroomSelector from "../components/ClassroomSelector";
import IncompleteSetupMessage from "../components/IncompleteSetupMessage";
import { getFullName } from "../helpers/childHelper";
import { getAttendanceLogsForClassroom } from "../api/AttendanceAPI";
import { formatDateToDDMM, getDayOfWeek } from "../helpers/dateTimeHelper";
import { shouldShowClassroomSelector } from "../helpers/teacherHelper";
import { getIncompleteSetupMessage } from "../helpers/setupHelper";
import "../css/AttendanceReportPage.css";

const AttendanceReportPage = () => {
  const { currentUser } = useAuth();
  const [selectedClassroom, setSelectedClassroom] = useState(null);
  const [attendanceLogs, setAttendanceLogs] = useState([]);
  const [children, setChildren] = useState([]);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    if (currentUser.classrooms && currentUser.classrooms.length > 0) {
      setSelectedClassroom(currentUser.classrooms[0].id); // Set initial classroom
    }
  }, [currentUser.classrooms]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedClassroom) {
        try {
          const data = await getAttendanceLogsForClassroom(selectedClassroom);
          setAttendanceLogs(data);

          // Create a map of children to display names
          const childMap = new Map();
          data.forEach((log) => {
            const fullName = getFullName(log);
            childMap.set(log.child_id, fullName);
          });
          setChildren(Array.from(childMap.entries()));

          // Extract unique sorted dates
          const sortedDates = Array.from(
            new Set(data.map((log) => log.date))
          ).sort((a, b) => new Date(b) - new Date(a));
          setDates(sortedDates);
        } catch (error) {
          console.error("Error fetching attendance logs:", error);
        } finally {
        }
      }
    };

    fetchData();
  }, [selectedClassroom]); // Trigger only when selectedClassroom changes

  const calculateAttendance = (childLogs) => {
    const total = childLogs.length;
    const presentCount = childLogs.filter((log) => log.present).length;
    const percentage = ((presentCount / total) * 100).toFixed(0);
    return { presentCount, total, percentage };
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "child_full_name",
      key: "child_full_name",
      fixed: "left",
      className: "child-name",
      render: (text, record) => (
        <Link to={`/children/${record.child_id}`}>{text}</Link>
      ),
    },
    {
      title: "Summary",
      dataIndex: "summary",
      className: "attendance-summary",
      key: "summary",
      render: (_, record) => {
        const childLogs = attendanceLogs.filter(
          (log) => log.child_id === record.child_id
        );
        const { presentCount, total, percentage } =
          calculateAttendance(childLogs);
        return (
          <div>
            <div>{`${presentCount}/${total}`}</div>
            <div>{`${percentage}%`}</div>
          </div>
        );
      },
    },
    ...dates.map((date) => {
      const formattedDate = formatDateToDDMM(date);
      const dayOfWeek = getDayOfWeek(date);
      return {
        title: (
          <div className="date-day-cell">
            <div>{formattedDate}</div>
            <div>{dayOfWeek}</div>
          </div>
        ),
        dataIndex: date,
        key: date,
        className: "attendance-cell",
        render: (present) =>
          present === undefined ? (
            <div className="empty-cell"></div>
          ) : (
            <div className={present ? "present" : "absent"}>
              {present ? "P" : "A"}
            </div>
          ),
      };
    }),
  ];

  const data = children.map(([child_id, child_full_name]) => {
    const childLogs = attendanceLogs.filter((log) => log.child_id === child_id);
    const rowData = { child_id, child_full_name };
    childLogs.forEach((log) => {
      rowData[log.date] = log.present;
    });
    return rowData;
  });

  const incompleteMessage = getIncompleteSetupMessage(currentUser);
  if (incompleteMessage) {
    return (
      <IncompleteSetupMessage
        title="Development Record"
        message={incompleteMessage}
      />
    );
  }

  return (
    <div>
      <Helmet>
        <title>Attendance Report | Maidan</title>
      </Helmet>

      <h1 className="page-heading">Attendance Report</h1>

      {shouldShowClassroomSelector(currentUser) && (
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <ClassroomSelector
              selectedClassroom={selectedClassroom}
              setSelectedClassroom={setSelectedClassroom}
            />
          </Col>
        </Row>
      )}

      {selectedClassroom && (
        <Table
          className="child-attendance-table"
          dataSource={data}
          columns={columns}
          pagination={false}
          rowKey={(record) => record.child_id}
          scroll={{ x: true }}
        />
      )}
    </div>
  );
};

export default AttendanceReportPage;
