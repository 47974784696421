// client/src/pages/DashboardPage.js
import React from "react";
import { Helmet } from "react-helmet-async";
import { useAuth } from "../contexts/AuthContext";
import OnboardingChecklist from "../components/OnboardingChecklist";
import MyPlannedActivities from "../components/record-keeper/MyPlannedActivities";
import IncompleteSetupMessage from "../components/IncompleteSetupMessage";
import { getIncompleteSetupMessage } from "../helpers/setupHelper";
import "../css/DashboardPage.css";

const DashboardPage = () => {
  const { currentUser } = useAuth();

  const showOnboardingChecklist =
    currentUser.role === "Owner" &&
    currentUser.onboardingStatus &&
    currentUser.onboardingStatus.status === false;

  if (showOnboardingChecklist) {
    return (
      <div className="dashboard-container">
        <Helmet>
          <title>Dashboard | Maidan</title>
        </Helmet>
        <OnboardingChecklist
          checklist={currentUser.onboardingStatus.checklist}
        />
      </div>
    );
  }

  const incompleteMessage = getIncompleteSetupMessage(currentUser);
  if (incompleteMessage) {
    return (
      <IncompleteSetupMessage
        title="Development Record"
        message={incompleteMessage}
      />
    );
  }

  return (
    <div className="dashboard-container">
      <Helmet>
        <title>Dashboard | Maidan</title>
      </Helmet>
      <div className="widget half-width">
        <MyPlannedActivities />
      </div>
    </div>
  );
};

export default DashboardPage;
