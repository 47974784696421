// src/components/LessonList.js
import React, { useState, useEffect } from "react";
import { Drawer, Button, Input, Row, Col, Select } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { createLesson, updateLesson } from "../api/SubjectAPI";
import LessonEditForm from "./LessonEditForm";
import { useAbility } from "../hooks/useAbility";
import useIsMobile from "../hooks/useIsMobile";
import requestHandler from "../utils/requestHandler";
import CustomTable from "./CustomTable";
import "../css/LessonList.css";

const { Search } = Input;

const LessonList = ({
  subjectId,
  lessons,
  refreshLessons,
  showNewButton = false,
  showSearchBar = false,
}) => {
  const [filteredLessons, setFilteredLessons] = useState(lessons || []);
  const [parentLessons, setParentLessons] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedParent, setSelectedParent] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(null);

  const ability = useAbility();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (subjectId) {
      setFilteredLessons(lessons);
      const parentData = lessons.filter((lesson) => lesson.parent_id === null);
      setParentLessons(parentData);
    }
  }, [subjectId, lessons]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredLessonsList = Array.isArray(filteredLessons)
    ? filteredLessons.filter((lesson) => {
        const matchesSearch =
          lesson.name &&
          lesson.name.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesParent = selectedParent
          ? lesson.parent_id === selectedParent
          : true;
        return matchesSearch && matchesParent;
      })
    : [];

  const handleEditLesson = (record) => {
    if (ability.can("update", "lesson")) {
      setSelectedLesson(record);
      setIsDrawerOpen(true);
    }
  };

  const handleNewLesson = () => {
    setSelectedLesson({});
    setIsDrawerOpen(true);
  };

  const handleFormFinish = async (lessonData) => {
    const dataWithSubjectId = { ...lessonData, subject_id: subjectId };

    if (selectedLesson && selectedLesson.id) {
      await requestHandler.execute(
        updateLesson(selectedLesson.id, dataWithSubjectId),
        "Lesson updated.",
        "Failed to update lesson"
      );
    } else {
      await requestHandler.execute(
        createLesson(dataWithSubjectId),
        "New lesson created.",
        "Failed to create lesson"
      );
    }
    setIsDrawerOpen(false);
    refreshLessons();
  };

  const columns = [
    {
      title: "Name",
      key: "sequence_name",
      align: "left",
      render: (text, record) => (
        <span className={record.parent_id ? "child-lesson" : "root-lesson"}>
          {record.formatted_sequence} {record.name}
        </span>
      ),
      width: 500,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "left",
      render: (text) => <span>{text}</span>,
    },
    {
      key: "action",
      align: "center",
      width: 100,
      render: (_, record) => (
        <Button
          icon={<EditOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            handleEditLesson(record);
          }}
          disabled={!ability.can("update", "lesson")}
        />
      ),
      width: 50,
    },
  ];

  return (
    <div>
      <Row
        gutter={8}
        align="middle"
        className="controls"
        wrap={false}
        style={{ marginBottom: 16 }}
      >
        {showNewButton && ability.can("create", "lesson") && (
          <Col xs={4} sm={2} md={1}>
            <Button
              type="default"
              onClick={handleNewLesson}
              icon={<PlusOutlined />}
              block={isMobile}
            />
          </Col>
        )}
        <Col xs={10} sm={10} md={11}>
          <Select
            placeholder="Filter by Parent Lesson"
            allowClear
            style={{ width: "100%" }}
            onChange={(value) => setSelectedParent(value)}
          >
            {parentLessons.map((parent) => (
              <Select.Option key={parent.id} value={parent.id}>
                {parent.formatted_sequence} {parent.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        {showSearchBar && (
          <Col xs={10} sm={12} md={12}>
            <Search
              placeholder="Search by name"
              allowClear
              onChange={handleSearch}
              style={{ width: "100%" }}
            />
          </Col>
        )}
      </Row>
      <CustomTable
        columns={columns}
        dataSource={filteredLessonsList}
        showSerialNo={false}
        alternateRowColors={false}
      />
      <Drawer
        width={350}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedLesson(null);
        }}
        open={isDrawerOpen}
        closable={false}
      >
        {isDrawerOpen && (
          <LessonEditForm
            subjectId={subjectId}
            lesson={selectedLesson}
            onFinish={handleFormFinish}
            refreshLessons={refreshLessons}
            title={
              selectedLesson && selectedLesson.id ? "Edit Lesson" : "New Lesson"
            }
          />
        )}
      </Drawer>
    </div>
  );
};

export default LessonList;
