// src/pages/public/PrivacyPolicyPage.js
import React from "react";

const PrivacyPolicyPage = () => {
  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f4f4f4",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          maxWidth: "800px",
          margin: "0 auto",
          backgroundColor: "#fff",
          padding: "20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h1>Privacy Policy</h1>
        <p>Last updated: {new Date().toLocaleDateString()}</p>

        <p>
          Welcome to IdeaBoard Technologies Pvt Ltd. This Privacy Policy
          explains how we collect, use, disclose, and safeguard your information
          when you visit our website or use our services. Please read this
          privacy policy carefully.
        </p>

        <h2>1. Information We Collect</h2>
        <p>
          We may collect information about you in a variety of ways. The
          information we may collect on the site includes:
        </p>
        <ul>
          <li>
            <strong>Personal Data:</strong> Personally identifiable information,
            such as your name, shipping address, email address, and telephone
            number.
          </li>
          <li>
            <strong>Derivative Data:</strong> Information our servers
            automatically collect when you access the site, such as your IP
            address, browser type, and operating system.
          </li>
          <li>
            <strong>Financial Data:</strong> Financial information such as
            credit card numbers, but we do not store your financial details on
            our servers.
          </li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <p>We may use the information we collect in the following ways:</p>
        <ul>
          <li>To provide, operate, and maintain our services.</li>
          <li>To manage your account and provide customer support.</li>
          <li>
            To contact you with newsletters, marketing or promotional materials,
            and other information.
          </li>
          <li>To detect, prevent, and address technical issues.</li>
        </ul>

        <h2>3. Sharing Your Information</h2>
        <p>
          We do not sell or rent your personal information to third parties.
          However, we may share your information with:
        </p>
        <ul>
          <li>Service providers who perform services on our behalf.</li>
          <li>Law enforcement, if required by law.</li>
        </ul>

        <h2>4. Your Rights</h2>
        <p>
          You have the right to access, update, or delete the personal
          information we have collected. You can do this by contacting us at
          privacy@ideaboard.com.
        </p>

        <h2>5. Security of Your Information</h2>
        <p>
          We use administrative, technical, and physical security measures to
          protect your personal information. While we take reasonable efforts to
          secure your information, no method of transmission over the internet
          or electronic storage is 100% secure, and we cannot guarantee absolute
          security.
        </p>

        <h2>6. Changes to This Policy</h2>
        <p>
          We may update this privacy policy from time to time to reflect changes
          in our practices. We will notify you of any significant changes by
          posting the new Privacy Policy on this page.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at info@ideaboard.xyz.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
