// client/src/api/ChildAPI.js
import axios from "axios";

const baseURL = "/children";

const api = axios.create({
  baseURL,
});

export const createChild = async (childData) => {
  try {
    const response = await api.post("/children", childData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getChildren = async () => {
  try {
    const response = await api.get("/children");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getChildById = async (childId = "") => {
  try {
    const response = await api.get(`/children/${childId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateChild = async (childId, childData) => {
  try {
    const response = await api.put(`/children/${childId}`, childData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createChildNote = async (childId, note) => {
  try {
    const response = await api.post("/child-notes", { childId, note });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateChildNote = async (noteId, note) => {
  try {
    const response = await api.put(`/child-notes/${noteId}`, { note });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteChildNote = async (noteId) => {
  try {
    await api.delete(`/child-notes/${noteId}`);
  } catch (error) {
    throw error;
  }
};

export const getChildNotes = async (childId) => {
  try {
    const response = await api.get(`/child-notes/${childId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getChildNotesByAuthorAndChild = async (authorId, childId) => {
  try {
    const response = await api.get(
      `/child-notes/author/${authorId}/child/${childId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
