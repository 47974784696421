// src/components/SubjectEditForm.js

import React, { useEffect, useRef } from "react";
import { Form, Input, Button, Typography } from "antd";
import { formFocusHelper } from "../helpers/formFocusHelper";

const { Title } = Typography;

const SubjectEditForm = ({
  subject = {},
  onFinish,
  refreshSubjects,
  title,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    return () => formFocusHelper(ref); 
  }, []);

  const handleFinish = (subjectData) => {
    onFinish(subjectData)
      .then(() => {
        refreshSubjects();
      })
      .catch((error) => {
        console.error("Error handling subject:", error);
      });
  };

  return (
    <>
      <Title level={2}>{title}</Title>
      <Form
        initialValues={subject}
        onFinish={handleFinish}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          name="name"
          label="Subject Name"
          rules={[
            { required: true, message: "Please input the subject name." },
          ]}
        >
          <Input ref={ref}/>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {subject.id ? "Update Subject" : "Create Subject"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default SubjectEditForm;
