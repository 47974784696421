import React from "react";
import { Row, Col } from "antd";

export const createResponsiveFilterLayout = (filters) => {
  const desktopLayout = [];
  const mobileLayout = [];
  let currentDesktopRow = [];
  let currentMobileRow = [];
  let desktopSpanSum = 0;
  let mobileSpanSum = 0;
  let rowIndex = 0; 

  const createRow = (items, isDesktop) => {
    if (!isDesktop) {
      const totalSpan = items.reduce((sum, item) => sum + item.mobileSpan, 0);
      if (totalSpan < 24) {
        const itemCount = items.length;
        const span = Math.floor(24 / itemCount);
        items = items.map((item) => ({ ...item, mobileSpan: span }));
      }
    }

    return (
      <Row gutter={8} style={{ marginBottom: 16 }} key={`row-${rowIndex++}`}>
        {items.map((item, index) => (
          <Col
            key={`item ${item.component}-${index}`} 
            span={isDesktop ? item.desktopSpan : item.mobileSpan}
          >
            {item.component}
          </Col>
        ))}
      </Row>
    );
  };

  const processMobileRow = () => {
    if (currentMobileRow.length > 0) {
      mobileLayout.push(createRow(currentMobileRow, false));
      currentMobileRow = [];
      mobileSpanSum = 0;
    }
  };

  filters.forEach((filter) => {
    if (filter.show !== false) {
      // Desktop layout
      if (desktopSpanSum + filter.desktopSpan > 24) {
        desktopLayout.push(createRow(currentDesktopRow, true));
        currentDesktopRow = [];
        desktopSpanSum = 0;
      }
      currentDesktopRow.push(filter);
      desktopSpanSum += filter.desktopSpan;

      // Mobile layout
      if (mobileSpanSum + filter.mobileSpan > 24) {
        processMobileRow();
      }
      currentMobileRow.push(filter);
      mobileSpanSum += filter.mobileSpan;
    }
  });

  // Add any remaining items
  if (currentDesktopRow.length > 0) {
    desktopLayout.push(createRow(currentDesktopRow, true));
  }
  if (currentMobileRow.length > 0) {
    processMobileRow();
  }

  return { desktopLayout, mobileLayout };
};
