// client/src/components/ChildEditForm.js
import React, { useEffect, useRef } from "react";
import { Form, Input, Button, Typography, Radio, Row, Col } from "antd";
import BloodGroupDropdown from "./BloodGroupDropdown";
import LanguageDropdown from "./LanguageDropdown";
import NationalityDropdown from "./NationalityDropdown";
import AadhaarInput from "./AadhaarInput";
import ProfilePhotoUploader from "./ProfilePhotoUploader";
import { formatDate } from "../helpers/dateTimeHelper";
import { formFocusHelper } from "../helpers/formFocusHelper";

const { Title } = Typography;

const ChildEditForm = ({ child = {}, onFinish, refreshChildren, title }) => {
  const ref = useRef(null);

  useEffect(() => {
    return () => formFocusHelper(ref); 
  }, []);

  const handleFinish = (childData) => {
    onFinish(childData)
      .then(() => {
        refreshChildren();
      })
      .catch((error) => {
        console.error("Error handling child:", error);
      });
  };

  return (
    <>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "24px" }}
      >
        <ProfilePhotoUploader
          memberId={child.id}
          initialPhotoUrl={child.profile_photo_url}
          refreshProfile={refreshChildren}
        />
        <Title level={2}>{title}</Title>
      </div>

      <Form
        initialValues={{
          ...child,
          date_of_birth: child.date_of_birth
            ? formatDate(child.date_of_birth)
            : null,
          date_of_joining: child.date_of_joining
            ? formatDate(child.date_of_joining)
            : null,
          date_of_leaving: child.date_of_leaving
            ? formatDate(child.date_of_leaving)
            : null,
          mother_tongue: child.mother_tongue || undefined,
          nationality: child.nationality || undefined,
        }}
        onFinish={handleFinish}
        layout="vertical"
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="sid"
              label="Child ID"
              rules={[
                { required: true, message: "Please input the child ID." },
              ]}
            >
              <Input ref={ref} autoFocus/>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[
                { required: true, message: "Please input the first name." },
                { pattern: /^[A-Za-z\s]+$/, message: "First name must contain only letters." }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
            name="last_name" 
            label="Last Name" 
            rules={[
              { pattern: /^[A-Za-z\s]*$/, message: "Last name must contain only letters." }
            ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="gender" label="Gender">
              <Radio.Group>
                <Radio value="Male">Male</Radio>
                <Radio value="Female">Female</Radio>
                <Radio value="Other">Other</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="date_of_birth" label="Date of Birth">
              <Input type="date" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="blood_group" label="Blood Group">
              <BloodGroupDropdown />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="mother_tongue" label="Mother Tongue">
              <LanguageDropdown />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="nationality" label="Nationality">
              <NationalityDropdown />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="aadhaar"
              label="Aadhaar"
              rules={[
                {
                  pattern: /^\d{12}$/,
                  message: "The Aadhaar number must be 12 digits.",
                },
              ]}
            >
              <AadhaarInput />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="date_of_joining" label="Date of Joining">
              <Input type="date" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="date_of_leaving" label="Date of Leaving">
              <Input type="date" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {child.id ? "Update Child" : "Create Child"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ChildEditForm;
