import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import SubjectList from "../components/SubjectList";
import MasteryLevelList from "../components/MasteryLevelList";
import LoadingSpinner from "../components/LoadingSpinner";
import { getSubjects } from "../api/SubjectAPI";
import { getMasteryLevels } from "../api/MasteryLevelAPI";

const SubjectsPage = () => {
  const [subjects, setSubjects] = useState([]);
  const [masteryLevels, setMasteryLevels] = useState([]);
  const [loading, setLoading] = useState(true);

  const refreshSubjects = async () => {
    setLoading(true);
    try {
      const data = await getSubjects();
      setSubjects(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshMasteryLevels = async () => {
    setLoading(true);
    try {
      const data = await getMasteryLevels();
      setMasteryLevels(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([refreshSubjects(), refreshMasteryLevels()]);
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Subjects | Maidan</title>
      </Helmet>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <h1 className="page-heading">Subjects</h1>
          <SubjectList
            subjects={subjects}
            refreshSubjects={refreshSubjects}
            showNewButton={true}
            showSearchBar={true}
          />

          <div style={{ height: "40px" }}></div>
          <h1 className="page-heading">Mastery Levels</h1>
          <MasteryLevelList
            masteryLevels={masteryLevels}
            refreshMasteryLevels={refreshMasteryLevels}
          />
        </>
      )}
    </div>
  );
};

export default SubjectsPage;
