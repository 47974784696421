// client/src/components/ChildCardList.js
import React from "react";
import { Card } from "antd";
import { Link } from "react-router-dom";
import { EditOutlined, FileTextOutlined } from "@ant-design/icons";
import { getFullName } from "../helpers/childHelper";
import { formatPhoneNumber } from "../helpers/formatHelper";
import { formatDate, calculateAge } from "../helpers/dateTimeHelper";
import "../css/ChildCardList.css";

const ChildCardList = ({
  children,
  onChildClick,
  openChildNotesModal,
  ability,
}) => {
  return (
    <div className="child-card-list">
      {children.map((child) => (
        <Card key={child.child_id} className="child-card">
          <div className="child-card-content">
            <img
              src={child.profile_photo_url || "/icons/profile-icon.png"}
              alt="Profile"
              className="profile-image"
            />
            <div className="child-info">
              <div className="child-header">
                <Link
                  to={`/children/${child.child_id}`}
                  className="child-card-name"
                >
                  {getFullName(child)}
                </Link>
                <div className="child-actions">
                  {ability.can("update", "children") && (
                    <EditOutlined
                      className="edit-icon"
                      onClick={() => onChildClick(child)}
                      style={{ display: "block", marginBottom: 8 }}
                    />
                  )}
                  {ability.can("access", "child-notes") && (
                    <FileTextOutlined
                      className="edit-icon"
                      onClick={() => openChildNotesModal(child)}
                      style={{ display: "block" }}
                    />
                  )}
                </div>
              </div>
              <div className="child-id-dob">
                {child.sid}
                {child.date_of_birth && (
                  <>
                    {" | "}
                    {formatDate(child.date_of_birth, "DD-MM-YY")} (
                    {calculateAge(child.date_of_birth)})
                  </>
                )}
              </div>

              <div className="child-grade">
                {child.grade ? child.grade.name : "No grade assigned"}
              </div>

              <div className="child-mother">
                <strong>Mother:</strong> {child.mother_name} <br />
                <a href={`tel:${child.mother_phone}`}>
                  {formatPhoneNumber(child.mother_phone)}
                </a>
                <br />
                {child.mother_email}
              </div>
              <div className="child-father">
                <strong>Father:</strong> {child.father_name} <br />
                <a href={`tel:${child.father_phone}`}>
                  {formatPhoneNumber(child.father_phone)}
                </a>
                <br />
                {child.father_email}
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default ChildCardList;
