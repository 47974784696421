// src/api/MasteryLevelAPI.js

import axios from "axios";

const baseURL = "/mastery-levels";

const api = axios.create({
  baseURL,
});

export const getMasteryLevels = async () => {
  try {
    const response = await api.get("/");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createMasteryLevel = async (masteryLevelData) => {
  try {
    const formData = new FormData();
    for (const key in masteryLevelData) {
      if (Array.isArray(masteryLevelData[key])) {
        masteryLevelData[key].forEach((value) => {
          formData.append(key, value);  
        });
      } else if(masteryLevelData[key]){
        formData.append(key, masteryLevelData[key]);
      }
    }
    const response = await api.post("/",formData, {
      'headers' : { 'Content-Type' : 'multipart/form-data' }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateMasteryLevel = async (masteryLevelId, masteryLevelData) => {
  try {
    const formData = new FormData();
    for (const key in masteryLevelData) {
      if (Array.isArray(masteryLevelData[key])) {
        masteryLevelData[key].forEach((value) => {
          formData.append(key, value);  
        });
      } else if(masteryLevelData[key]){
        formData.append(key, masteryLevelData[key]);
      }
    }
    const response = await api.put(`/${masteryLevelId}`,formData, {
      'headers' : { 'Content-Type' : 'multipart/form-data' }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getChildrenInClassroom = async (classroomId) => {
  try {
    const response = await api.get(`/classrooms/${classroomId}/children`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
