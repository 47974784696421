// src/pages/Terms.js
import React from "react";

const TermsPage = () => {
  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f4f4f4",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          maxWidth: "800px",
          margin: "0 auto",
          backgroundColor: "#fff",
          padding: "20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h1>Terms & Conditions</h1>

        <h2>Introduction</h2>
        <p>
          Welcome to <strong>IdeaBoard Technologies Pvt Ltc.</strong>. By using
          our website or services, you agree to the following terms and
          conditions. Please read them carefully.
        </p>

        <h2>Use of Services</h2>
        <p>
          By accessing or using our services, you agree to comply with these
          Terms & Conditions. You must not use our services for any illegal or
          unauthorized purposes.
        </p>

        <h2>Account Responsibility</h2>
        <p>
          You are responsible for maintaining the confidentiality of your
          account and password, as well as all activities that occur under your
          account.
        </p>

        <h2>Intellectual Property</h2>
        <p>
          All content and materials on our platform, including text, graphics,
          logos, and images, are the intellectual property of{" "}
          <strong>IdeaBoard Technologies Pvt Ltd</strong> and are protected by
          applicable laws.
        </p>

        <h2>Limitations of Liability</h2>
        <p>
          <strong>IdeaBoard Technologies Pvt Ltd</strong> is not liable for any
          damages or losses arising from the use or inability to use our
          services. We do not guarantee that our services will be error-free or
          uninterrupted.
        </p>

        <h2>Modifications to Terms</h2>
        <p>
          We reserve the right to modify these Terms & Conditions at any time.
          Changes will be posted on this page, and your continued use of our
          services constitutes acceptance of the revised terms.
        </p>

        <h2>Contact Information</h2>
        <p>
          If you have any questions regarding these Terms & Conditions, please
          contact us at{" "}
          <a href="mailto:info@ideaboard.xyz">info@ideaboard.xyz</a>.
        </p>
      </div>
    </div>
  );
};

export default TermsPage;
